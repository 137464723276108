<template>
    <div class="payment">
        <div class="paymentSecure">
            <p class="paymentSecure_title">{{ translateWord('Método de pagamento seguro') }}</p>

            <div class="paymentSecure_stamps">
                <div class="paymentSecure_stamps_item">
                    <icon class="paymentSecure_stamps_item_icon" name="shield" />
                    <p class="paymentSecure_stamps_item_name">{{ translateWord('100% Seguro') }}</p>
                </div>

                <div class="paymentSecure_stamps_item">
                    <icon class="paymentSecure_stamps_item_icon" name="lock" />
                    <p class="paymentSecure_stamps_item_name">{{ translateWord('SSL Certificado') }}</p>
                </div>
            </div>
        </div>

        <!-- Metodos de Pagamento -->
        <div class="payment_paymentMethods">
            
            <!-- myCards-->
            <div v-if="paymentMethodEnabled.myCards" class="payment_paymentMethods_item payment_paymentMethods_itemMyCards" :class="{paymentMethodsActive: paymentMethodSelected == 'myCards'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('myCards')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>
                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/my-cards.png">
                        <p class="payment_paymentMethods_item_menu_data_name">{{ translateWord('Meus Cartões') }}</p>
                    </div>

                    <div  v-if="paymentMethodSelected == 'myCards'" class="payment_paymentMethods_item_menu_info">{{ translateWord('Selecione abaixo seu cartão vinculado com a builderall para realizar a compra com apenas 1 click') }}</div>
                </div>

                <div v-if="paymentMethodSelected == 'myCards'" class="payment_paymentMethods_item_content">
                    <div 
                        v-for="(cardCredit, index) in myCards"
                        :key="index" 
                        class="payment_paymentMethods_item_myCards" 
                        :class="{payment_paymentMethods_item_myCardsActive: cardCredit.selected}"
                        @click="selectedMyCard(index)"
                    >
                        <div class="payment_paymentMethods_item_myCards_Number payment_paymentMethods_item_myCards_separete">
                            <div class="payment_paymentMethods_item_myCards_check"></div>
                            
                            <img v-if="!product.isTheCurrency.eur" class="payment_paymentMethods_item_myCards_brand" :src="require(`../assets/card-brands/${cardCredit.brand}.jpg`)" />

                            <p class="payment_paymentMethods_item_myCards_number">
                                **** **** **** {{ cardCredit.last_four_digits }} 
                            </p>

                            <div class="payment_paymentMethods_item_myCards_validate">
                                <span class="payment_paymentMethods_item_myCards_validate_year">{{ cardCredit.year }}-</span>
                                <span class="payment_paymentMethods_item_myCards_validate_month">{{ cardCredit.month }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 
        <template v-else-if="paymentMethodSelected == 'myCards'">
            <div class="payment_card_myCard">
                <div class="payment_card_myCard_imgs">
                    <img src="../assets/builderall-logo.png" alt="" class="payment_card_myCard_imgs_logo">
                    <img src="../assets/chip-card.png" alt="" class="payment_card_myCard_imgs_chip">
                </div>

                <div class="payment_card_myCard_info">
                    <p class="payment_card_myCard_info_number">
                        **** **** **** {{ myCardSelectedPayment.last_four_digit }}
                    </p>

                    <div>
                        <p class="payment_card_myCard_info_due">{{ myCardSelectedPayment.due_month }} / {{ myCardSelectedPayment.due_year }}</p>
                        <img :src="user.cards.brand_img" alt="" class="payment_card_myCard_info_brand">
                    </div>
                </div>
            </div>
        </template> -->

            <!-- Cartão de credito -->
            <div v-if="paymentMethodEnabled.creditCard" class="payment_paymentMethods_item payment_paymentMethods_itemCreditCard" :class="{paymentMethodsActive: paymentMethodSelected == 'creditCard'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('creditCard')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>

                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/credit-card.svg">
                        <p class="payment_paymentMethods_item_menu_data_name">{{ translateWord('Cartão de Credito') }}</p>
                    </div>

                    <div class="payment_paymentMethods_item_menu_info"></div>
                </div>

                <div v-if="paymentMethodSelected == 'creditCard'" class="payment_paymentMethods_item_content">
                    <div class="payment_paymentMethods_item_content_title">
                        <p>{{ translateWord('Adicionar detalhes de pagamento') }}</p>
                        <p>{{ translateWord('Por favor, insira seus detalhes de pagamento abaixo') }}</p>
                    </div>

                    <!-- Stripe -->
                    <template v-if="stripe.isVisible && paymentMethodSelected == 'creditCard'">
                        <form name="form-stripe">
                            <div class="col-xs-24">
                                <at-input v-model="stripe.holderName" :status="stripe.fieldsValidators.holderName.error ? 'error' : ''" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                    <template slot="prepend">
                                        <span class="icon icon-user"></span>
                                    </template>
                                </at-input>
                            </div>

                            <div id="stripe-element"></div>
                        </form>
                    </template>

                    <!-- Pagar.me -->
                    <template v-if="pagarme.isVisible && paymentMethodSelected == 'creditCard'">
                        <form name="form-pagarme" class="row">
                            <div class="payment_card_name col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-model="pagarme.card.holderName" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_number col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'#### #### #### ####'" v-model="pagarme.card.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_validate col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'##/##'" v-model="pagarme.card.validate" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Validade do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_cvc col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'####'" v-model="pagarme.card.cvc" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CVV/CVC')">
                                </at-input>
                            </div>
                        </form>

                        <p class="payment_paymentMethods_item_title">{{ translateWord('Informações adicionais') }}:</p>

                        <div class="row">
                            <div class="payment_card_phone col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="['(##) ####-####', '(##) #####-####']" v-model="pagarme.card.phone.areaCodeAndNumber" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Telefone')">
                                </at-input>
                            </div>
                            <div class="payment_card_cpf col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="pagarme.card.cpfOrCnpj" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CPF ou CNPJ')">
                                </at-input>
                            </div>
                        </div>

                        <div v-if="['EUR', 'BRL'].includes(product.currency) && ['creditCard', 'sepa', 'boleto'].includes(paymentMethodSelected) && !this.isFirstPaymentUpLine" class="payment_paymentMethods_item_address">
                            <p class="payment_paymentMethods_item_title">{{ translateWord('Endereço') }}:</p>

                            <div class="row">
                                <div class="payment_address_postalCode col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                    <template v-if="product.currency == 'BRL'">
                                        <at-input v-mask="['#########']" v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                    </template>
                                    <template v-else>
                                        <at-input v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                    </template>
                                </div>
                                <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'EUR'">
                                    <at-input v-model="address.country" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('País')"></at-input>
                                </div>
                                <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                    <at-input maxlength="4" v-model="address.stateCode" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Estado')"></at-input>
                                </div>
                                <div class="payment_address_city col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                    <at-input v-model="address.city" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Cidade')"></at-input>
                                </div>
                                <div class="payment_address_district col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                    <at-input v-model="address.district" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Bairro')"></at-input>
                                </div>
                                <div class="payment_address_addressCompleted" :class="[product.currency == 'BRL' ? 'col-xs-12 col-sm-12 col-md-12  col-lg-16' : 'col-xs-12 col-sm-12 col-md-12  col-lg-12']">
                                    <at-input v-model="address.street" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Rua')"></at-input>
                                </div>
                                <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-8" v-if="product.currency == 'BRL'">
                                    <at-input v-model="address.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero')"></at-input>
                                </div>
                                <div class="payment_address_country col-xs-24 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                    <at-input v-model="address.complement" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Complemento')"></at-input>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Adyen -->
                    <template v-if="adyen.isVisible && paymentMethodSelected == 'creditCard'">
                        <form name="form-adyen" class="row">
                            <div class="payment_card_name col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-model="adyen.card.holderName" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_number col-xs-24 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'#### #### #### ####'" v-model="adyen.card.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_validate col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'##/##'" v-model="adyen.validate" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Validade do cartão')">
                                </at-input>
                            </div>

                            <div class="payment_card_cvc col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-mask="'####'" v-model="adyen.card.cvc" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CVV/CVC')">
                                </at-input>
                            </div>
                        </form>

                        <form method="GET" :action="adyen.threeDS.issuerUrl" style="display: none;">
                            <input type="hidden" name="PaReq" :value="adyen.threeDS.paRequest" />
                            <input type="hidden" name="MD" :value="adyen.threeDS.md" />
                            <input type="hidden" name="TermUrl" :value="adyen.threeDS.termUrl" />

                            <input type="submit" class="button" value="enviar" ref="threeDSAdyen"/>
                        </form>

                        <div v-if="['EUR'].includes(product.currency) && ['creditCard', 'sepa'].includes(paymentMethodSelected) && !this.isFirstPaymentUpLine" class="payment_paymentMethods_item_address">
                            <p class="payment_paymentMethods_item_title">{{ translateWord('Endereço') }}:</p>

                            <div class="row">
                                <div class="payment_address_postalCode col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                    <at-input v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                </div>
                                <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                    <at-input v-model="address.country" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('País')"></at-input>
                                </div>
                                <div class="payment_address_city col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                    <at-input v-model="address.city" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Cidade')"></at-input>
                                </div>
                                <div class="payment_address_addressCompleted col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <at-input v-model="address.street" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Rua')"></at-input>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="payment_paymentMethods_itemCreditCard_brands">
                        <img class="payment_paymentMethods_itemCreditCard_brands_image" src="../assets/card-brands/visa.jpg" alt="Visa" title="Visa">
                        <img class="payment_paymentMethods_itemCreditCard_brands_image" src="../assets/card-brands/mastercard.jpg" alt="Master Card" title="Master Card">
                        <img class="payment_paymentMethods_itemCreditCard_brands_image" src="../assets/card-brands/american_express.jpg" alt="American Express" title="American Express">
                        <img class="payment_paymentMethods_itemCreditCard_brands_image" src="../assets/card-brands/discover-card.png" alt="Discover" title="Discover">
                        <img class="payment_paymentMethods_itemCreditCard_brands_image" src="../assets/card-brands/diners-club.jpg" alt="Diners Club" title="Diners Club">
                    </div>
                </div>
            </div>

            <!-- Pix -->
            <div v-if="paymentMethodEnabled.pix" class="payment_paymentMethods_item payment_paymentMethods_itemPix" :class="{paymentMethodsActive: paymentMethodSelected == 'pix'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('pix')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>
                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/pix.png">
                        <p class="payment_paymentMethods_item_menu_data_name">{{ translateWord('Pix') }}</p>
                    </div>

                    <div class="payment_paymentMethods_item_menu_info" v-if="paymentMethodSelected == 'pix'">
                        <p>Para gerar o PIX de pagamento, preencha os dados abaixo. Em seguida, basta clicar no botão de gerar PIX.</p>
                    </div>
                </div>

                <div v-if="paymentMethodSelected == 'pix'" class="payment_paymentMethods_item_content">
                    <div class="row">
                        <div class="payment_card_phone col-xs-24 col-sm-12 col-md-12  col-lg-12">
                            <at-input v-mask="['(##) ####-####', '(##) #####-####']" v-model="pagarme.card.phone.areaCodeAndNumber" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Telefone')">
                            </at-input>
                        </div>
                        <div class="payment_card_cpf col-xs-24 col-sm-12 col-md-12  col-lg-12">
                            <at-input v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="pagarme.card.cpfOrCnpj" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CPF ou CNPJ')">
                            </at-input>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Boleto -->
            <div v-if="paymentMethodEnabled.boleto"  class="payment_paymentMethods_item" :class="{paymentMethodsActive: paymentMethodSelected == 'boleto'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('boleto')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>
                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/boleto.svg">
                        <p class="payment_paymentMethods_item_menu_data_name">{{ translateWord('Boleto') }}</p>
                    </div>

                    <div class="payment_paymentMethods_item_menu_info" v-if="paymentMethodSelected == 'boleto'">
                        <p>{{ translateWord('Para gerar seu boleto de pagamento, preencha os dados abaixo. Em seguida basta clicar no botão gerar boleto') }}</p>
                    </div>
                </div>

                <div v-if="paymentMethodSelected == 'boleto'" class="payment_paymentMethods_item_content">
                    <p class="payment_paymentMethods_item_title">{{ translateWord('Informações adicionais') }}:</p>

                    <div class="row">
                        <div class="payment_card_phone col-xs-24 col-sm-12 col-md-12  col-lg-12">
                            <at-input v-mask="['(##) ####-####', '(##) #####-####']" v-model="pagarme.card.phone.areaCodeAndNumber" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Telefone')">
                            </at-input>
                        </div>
                        <div class="payment_card_cpf col-xs-24 col-sm-12 col-md-12  col-lg-12">
                            <at-input v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="pagarme.card.cpfOrCnpj" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CPF ou CNPJ')">
                            </at-input>
                        </div>
                    </div>

                    <div class="payment_paymentMethods_item_address">
                        <p class="payment_paymentMethods_item_title">{{ translateWord('Endereço') }}:</p>

                        <div class="row">
                            <div class="payment_address_postalCode col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <template v-if="product.currency == 'BRL'">
                                    <at-input v-mask="['#########']" v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                </template>
                                <template v-else>
                                    <at-input v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                </template>
                            </div>
                            <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'EUR'">
                                <at-input v-model="address.country" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('País')"></at-input>
                            </div>
                            <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                <at-input maxlength="4" v-model="address.stateCode" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Estado')"></at-input>
                            </div>
                            <div class="payment_address_city col-xs-12 col-sm-12 col-md-12  col-lg-12">
                                <at-input v-model="address.city" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Cidade')"></at-input>
                            </div>
                            <div class="payment_address_district col-xs-12 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.district" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Bairro')"></at-input>
                            </div>
                            <div class="payment_address_addressCompleted" :class="[product.currency == 'BRL' ? 'col-xs-12 col-sm-12 col-md-12  col-lg-16' : 'col-xs-12 col-sm-12 col-md-12  col-lg-12']">
                                <at-input v-model="address.street" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Rua')"></at-input>
                            </div>
                            <div class="payment_address_country col-xs-12 col-sm-12 col-md-12  col-lg-8" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero')"></at-input>
                            </div>
                            <div class="payment_address_country col-xs-24 col-sm-12 col-md-12  col-lg-12" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.complement" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Complemento')"></at-input>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Paypal -->
            <div v-if="paymentMethodEnabled.paypal" class="payment_paymentMethods_item payment_paymentMethods_itemPaypal" :class="{paymentMethodsActive: paymentMethodSelected == 'paypal'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('paypal')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>
                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/paypal.png">
                        <p class="payment_paymentMethods_item_menu_data_name">{{ translateWord('Paypal') }}</p>
                    </div>

                    <div class="payment_paymentMethods_item_menu_info"></div>
                </div>

                <div v-if="paymentMethodSelected == 'paypal'" class="payment_paymentMethods_item_content">
                    <!--<template v-if="isPaymentCountryPrice">{{ translateWord('Pagamento por Country Price, somente em USD.') }}</template>
                    <template v-else>{{ translateWord('Clicando no botão abaixo, você será redirecionado para o site do paypal, para concluir sua compra com segurança') }}</template>-->
                    {{ translateWord('Clicando no botão abaixo, você será redirecionado para o site do paypal, para concluir sua compra com segurança') }}
                </div>
            </div>

            <!-- PagSeguro -->
            <div v-if="paymentMethodEnabled.pagSeguro" class="payment_paymentMethods_item payment_paymentMethods_itemPagseguro" :class="{paymentMethodsActive: paymentMethodSelected == 'pagSeguro'}">
                <div class="payment_paymentMethods_item_menu" @click="paymentMethod('pagSeguro')">
                    <div class="payment_paymentMethods_item_menu_data">
                        <div class="payment_paymentMethods_item_menu_data_symbol">
                            <span class="payment_paymentMethods_item_menu_data_symbol_ball"></span>
                        </div>
                        <img class="payment_paymentMethods_item_menu_data_img" src="../assets/pagseguro-logo.png">
                        <p class="payment_paymentMethods_item_menu_data_name">PagSeguro</p>
                    </div>

                    <div class="payment_paymentMethods_item_menu_info"></div>
                </div>

                <div v-if="paymentMethodSelected == 'pagSeguro'" class="payment_paymentMethods_item_content">
                    Conteudo
                </div>
            </div>
        </div>

        <div class="payment_actions">
            <at-button @click="btnContinue" class="btn_formWidth" type="primary">
                <span v-if="paymentMethodSelected == 'boleto'">{{ translateWord('Gerar boleto') }}</span>
                <span v-else-if="paymentMethodSelected == 'pix'">Gerar PIX</span>
                <span v-else-if="paymentMethodSelected == 'paypal'">
                    <!--<template v-if="isPaymentCountryPrice">{{ translateWord('Ir para plano USD') }}</template>
                    <template v-else>{{ translateWord('Ir para paypal') }}</template>-->
                    <template>{{ translateWord('Ir para paypal') }}</template>
                </span>
                <span v-else>{{ translateWord('Finalizar pagamento') }}</span>
            </at-button>

            <at-button v-if="devMode" @click="nextStepWithEmit" class="payment_actions_devModePaymentConfirmation" type="primary">
                <span>Ir para pagamento finalizado</span>
            </at-button>
        </div>
        <!-- <div class="row">
            <div class="col-lg-12">
                <Box padding-big>
                    <div class="payment_infoPayment">
                        <h2>{{ translateWord('Informações de Pagamento') }}</h2>

                        <div class="payment_infoPayment_stamps">
                            <div class="payment_infoPayment_stamps_item">
                                <img src="../assets/imagem/shield2.svg" :alt="translateWord('Segurança')" :title="translateWord('Segurança')">
                                <p class="payment_infoPayment_stamps_item_desc">{{ translateWord('100% Seguro') }}</p>
                            </div>

                            <div class="payment_infoPayment_stamps_item">
                                <img src="../assets/imagem/security2.svg" :alt="translateWord('Certificado de SSL')" :title="translateWord('Certificado de SSL')">
                                <p class="payment_infoPayment_stamps_item_desc">{{ translateWord('Certificado de SSL') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="payment_card">
                        
                        <template v-if="stripe.isVisible">
                            <form name="form-stripe">
                                <div class="col-xs-24">
                                    <at-input v-model="stripe.holderName" :status="stripe.fieldsValidators.holderName.error ? 'error' : ''" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                        <template slot="prepend">
                                            <span class="icon icon-user"></span>
                                        </template>
                                    </at-input>
                                </div>

                                <div id="stripe-element"></div>
                            </form>
                        </template>

                        <template v-else-if="adyen.isVisible && paymentMethodSelected == 'creditCard'">
                            <form name="form-adyen" class="row">
                                <div class="payment_card_name col-lg-24">
                                    <at-input v-model="adyen.card.holderName" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_number col-lg-24">
                                    <at-input v-model="adyen.card.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_validate col-lg-12">
                                    <at-input v-mask="'##/##'" v-model="adyen.validate" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Validade do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_cvc col-lg-12">
                                    <at-input v-model="adyen.card.cvc" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CVV/CVC')">
                                    </at-input>
                                </div>
                            </form>

                            <form method="GET" :action="adyen.threeDS.issuerUrl" style="display: none;">
                                <input type="hidden" name="PaReq" :value="adyen.threeDS.paRequest" />
                                <input type="hidden" name="MD" :value="adyen.threeDS.md" />
                                <input type="hidden" name="TermUrl" :value="adyen.threeDS.termUrl" />

                                <input type="submit" class="button" value="enviar" ref="threeDSAdyen"/>
                            </form>
                        </template>

                        <template v-else-if="pagarme.isVisible && ['creditCard', 'boleto'].includes(paymentMethodSelected)">
                            <form name="form-pagarme" class="row" v-if="paymentMethodSelected == 'creditCard'">
                                <div class="payment_card_name col-lg-24">
                                    <at-input v-model="pagarme.card.holderName" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Nome do titular do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_number col-lg-24">
                                    <at-input v-mask="'#### #### #### ####'" v-model="pagarme.card.number" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Numero do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_validate col-lg-12">
                                    <at-input v-mask="'##/##'" v-model="pagarme.card.validate" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Validade do cartão')">
                                    </at-input>
                                </div>

                                <div class="payment_card_cvc col-lg-12">
                                    <at-input v-mask="'####'" v-model="pagarme.card.cvc" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('CVV/CVC')">
                                    </at-input>
                                </div>
                            </form>

                            <div class="payment_boleto" v-if="paymentMethodSelected == 'boleto'">
                                <img class="payment_boleto_image" src="../assets/boleto.svg" alt="Boleto" title="Boleto">
                                <p class="payment_boleto_desc">Para gerar seu boleto de pagamento, preencha os dados abaixo. Em seguida, basta clicar no botão gerar boleto</p>
                            </div>

                            <h2>Informações adicionais:</h2>

                            <div class="row">
                                <div class="payment_card_phone col-lg-24">
                                    <at-input v-mask="['(##) ####-####', '(##) #####-####']" v-model="pagarme.card.phone.areaCodeAndNumber" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Telefone Ex: 31 99999-9999">
                                    </at-input>
                                </div>
                                <div class="payment_card_cpf col-lg-24">
                                    <at-input v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="pagarme.card.cpfOrCnpj" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="CPF ou CNPJ">
                                    </at-input>
                                </div>
                            </div>
                        </template>

                        <template v-else-if="adyen.isVisible && paymentMethodSelected == 'sepa'">
                            <form name="form-adyen" class="row">
                                <div class="payment_card_name col-lg-24">
                                    <at-input v-model="adyen.sepa.ownerName" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Owner Name">
                                    </at-input>
                                </div>

                                <div class="payment_card_number col-lg-24">
                                    <at-input v-model="adyen.sepa.ibanNumber" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="IBAN Number">
                                    </at-input>
                                </div>
                            </form>
                        </template>

                        <template v-else-if="paymentMethodSelected == 'paypal'">
                            <p class="payment_card_paypal">{{ translateWord('Você será redirecionado para o site do paypal, para concluir sua compra com segurança') }}</p>
                        </template>

                        <template v-else-if="(product.currency == 'BRL' || isFirstPaymentUpLine) && paymentMethodSelected == 'pagSeguro'">
                            <p class="payment_card_paypal">{{ translateWord('Ao clicar em PAGAR COM PAGSEGURO, exibirá um popup para finalizar o pagamento com segurança') }}</p>
                        </template>

                        <template v-else-if="paymentMethodSelected == 'myCards'">
                            <div class="payment_card_myCard">
                                <div class="payment_card_myCard_imgs">
                                    <img src="../assets/builderall-logo.png" alt="" class="payment_card_myCard_imgs_logo">
                                    <img src="../assets/chip-card.png" alt="" class="payment_card_myCard_imgs_chip">
                                </div>

                                <div class="payment_card_myCard_info">
                                    <p class="payment_card_myCard_info_number">
                                        **** **** **** {{ myCardSelectedPayment.last_four_digit }}
                                    </p>

                                    <div>
                                        <p class="payment_card_myCard_info_due">{{ myCardSelectedPayment.due_month }} / {{ myCardSelectedPayment.due_year }}</p>
                                        <img :src="user.cards.brand_img" alt="" class="payment_card_myCard_info_brand">
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <h2>{{ translateWord('Não foi possivel exibir os campos para finalizar o pagamento. Favor tente novamente') }}</h2>
                        </template>

                    </div>
                    
                    <div v-if="['EUR', 'BRL'].includes(product.currency) && ['creditCard', 'sepa', 'boleto'].includes(paymentMethodSelected) && !this.isFirstPaymentUpLine" class="payment_address">
                        <h2>{{ translateWord('Endereço') }}:</h2>

                        <div class="row">
                            <div class="payment_address_postalCode col-lg-12">
                                <template v-if="product.currency == 'BRL'">
                                    <at-input v-mask="['#########']" v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                </template>
                                <template v-else>
                                    <at-input v-model="address.zipCode" @blur="searchLocaleZipCode" size="large" :placeholder="translateWord('CEP')"></at-input>
                                </template>
                            </div>
                            <div class="payment_address_country col-lg-12" v-if="product.currency == 'EUR'">
                                <at-input v-model="address.country" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('País')"></at-input>
                            </div>
                            <div class="payment_address_country col-lg-12" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.stateCode" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Estado"></at-input>
                            </div>
                            <div class="payment_address_city col-lg-12">
                                <at-input v-model="address.city" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Cidade')"></at-input>
                            </div>
                            <div class="payment_address_district col-lg-12" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.district" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Bairro"></at-input>
                            </div>
                            <div class="payment_address_addressCompleted" :class="[product.currency == 'BRL' ? 'col-lg-16' : 'col-lg-12']">
                                <at-input v-model="address.street" @keyup.native="verifyActiveContinueStep()" size="large" :placeholder="translateWord('Rua')"></at-input>
                            </div>
                            <div class="payment_address_country col-lg-8" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.number" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Numero"></at-input>
                            </div>
                            <div class="payment_address_country col-lg-24" v-if="product.currency == 'BRL'">
                                <at-input v-model="address.complement" @keyup.native="verifyActiveContinueStep()" size="large" placeholder="Complemento"></at-input>
                            </div>
                        </div>
                    </div>

                    <div class="payment_stamps">
                        <div class="payment_stamps_item">
                            <img src="../assets/imagem/shield2.svg" :alt="translateWord('Segurança')" :title="translateWord('Segurança')">
                            <p class="payment_stamps_item_desc">{{ translateWord('100% Seguro') }}</p>
                        </div>

                        <div class="payment_stamps_item">
                            <img src="../assets/imagem/security2.svg" :alt="translateWord('Certificado de SSL')" :title="translateWord('Certificado de SSL')">
                            <p class="payment_stamps_item_desc">{{ translateWord('Certificado de SSL') }}</p>
                        </div>
                    </div>
                    
                    <div v-if="paymentMethodSelected == 'creditCard'" class="payment_paymentMethodBrands">
                        <img src="../assets/card-brands/visa.jpg" alt="Visa" title="Visa">
                        <img src="../assets/card-brands/mastercard.jpg" alt="Master Card" title="Marter Card">
                        <img src="../assets/card-brands/american-express.png" alt="American Express" title="American Express">
                        <img src="../assets/card-brands/discover-card.png" alt="Discover Card" title="Discover Card">
                        <img src="../assets/card-brands/diners-club.jpg" alt="Diners Club" title="Diners Club">
                        <img v-show="false" src="../assets/paypal.png" alt="Paypal" title="Paypal">
                    </div>
                    
                    <div class="payment_actions">
                        <p class="payment_actions_desc" v-if="paymentMethodSelected != 'pagSeguro' && paymentMethodSelected != 'myCards' && paymentMethodSelected != 'boleto' && (!isFirstPaymentUpLine || !isUserFatherToReceiveByGateway) && !isPaymentDomain">
                            {{ translateWord('Ao salvar os detalhes do cartão, você autoriza a Builderall a salvar seus dados para futuras compras, assinaturas e renovações.') }} 
                        </p>
                        <p v-else-if="isFirstPaymentUpLine" class="payment_actions_desc">
                            {{ translateWord('Este pagamento faz parte do Programa de Comissão Instantânea da Builderall e será redirecionado integralmente ao seu patrocinador') }}
                        </p>
                        <a class="payment_actions_prev" @click.prevent="btnPrev">< {{ translateWord('Voltar') }}</a>
                    </div>
                </Box>
            </div>
            <div class="col-lg-12">
                <Box>
                    <div class="payment_PlanOrFeeDetails">
                        <div class="payment_PlanOrFeeDetails_info">
                            <h2 class="payment_PlanOrFeeDetails_info_name">{{ product.title }}</h2>
                            <p class="payment_PlanOrFeeDetails_info_desc">{{ product.description }}</p>
                        </div>
                        
                        <p v-if="!hasCouponAndIsValid && !product.isTrialDays && !product.isAmountFirstMonth && !fakeAmountPlanOrFee" class="payment_PlanOrFeeDetails_amount">{{ product.currencySymbol }} {{ product.amount }}</p>
                        <p v-else-if="product.isTrialDays && !product.isAmountFirstMonth && !fakeAmountPlanOrFee" class="payment_PlanOrFeeDetails_amount trialDays">{{ product.currencySymbol }} 0</p>
                        <p v-else-if="product.isAmountFirstMonth && !fakeAmountPlanOrFee" class="payment_PlanOrFeeDetails_amount">{{ product.currencySymbol }} {{ product.amountFirstMonth }}</p>
                        <div v-else-if="fakeAmountPlanOrFee">
                            <p class="payment_PlanOrFeeDetails_amountWithoutDiscount"> <del>{{ product.currencySymbol }} {{ fakeAmountPlanOrFee }}</del></p>
                            <p class="payment_PlanOrFeeDetails_amountDiscount">{{ product.currencySymbol }} {{ product.amount }}</p>
                        </div>
                        <div v-else>
                            <p class="payment_PlanOrFeeDetails_amountWithoutDiscount"> <del>{{ product.currencySymbol }} {{ product.amount }}</del></p>
                            <p class="payment_PlanOrFeeDetails_amountDiscount">{{ product.currencySymbol }} {{ amountDiscount }}</p>
                        </div>
                        
                        <div class="payment_PlanOrFeeDetails_addCoupon" v-if="!fakeAmountPlanOrFee && !isPaymentDomain && !isPaymentStorage && !isPaymentDomainExtraConnection">
                            <at-input v-model="couponAddUser" :disabled="this.$route.query.coupon && coupon.isValid" class="payment_PlanOrFeeDetails_addCoupon_field" size="large" :placeholder="translateWord('Cupom de desconto')">
                                <template slot="prepend">
                                    <span class="icon icon-tag"></span>
                                </template>
                            </at-input>

                            <at-button :disabled="this.$route.query.coupon && coupon.isValid || couponAddUser == ''" @click="validCoupon" class="payment_PlanOrFeeDetails_addCoupon_action" type="primary">
                                <span>{{ translateWord('VALIDAR') }}</span>
                            </at-button>
                        </div>
                    </div>
                    
                    <div slot="footer">                        
                        <div class="payment_coupon" :class="{payment_couponInvalid : !coupon.isValid}" v-if="hasCoupon">
                            <div class="payment_coupon_info">
                                <h2 class="payment_coupon_info_name">{{ translateWord('Cupom de desconto') }}</h2>

                                <div class="payment_coupon_info_details">
                                    <span class="payment_coupon_info_details_item">
                                        {{ translateWord('Valido') }}:
                                        <span class="payment_coupon_info_details_item_couponValid" v-if="coupon.isValid"><i class="icon icon-check-circle"></i> <b>{{ translateWord('SIM') }}</b></span>
                                        <span class="payment_coupon_info_details_item_couponInvalid" v-else><i class="icon icon-x-circle"></i> <b>{{ translateWord('NÂO') }}</b></span>
                                    </span>
    
                                    <span class="payment_coupon_info_details_item">{{ translateWord('Código') }}: {{ coupon.code }}</span>

                                    <span class="payment_coupon_info_details_item" v-if="['coupon_already_used', 'coupon_is_valid'].includes(coupon.reason)">
                                        {{ translateWord('Utilizado') }}: 
                                        <span v-if="coupon.reason == 'coupon_already_used'">{{ translateWord('SIM') }}</span>
                                        <span v-else>{{ translateWord('NÂO') }}</span>
                                    </span>
                                    
                                    <p v-if="!this.hasCouponAndIsValid" class="planOrFeeDetails_planOrFeeDescription_invalidCoupon">
                                        {{ reasonCouponInvalid }}
                                    </p>
                                </div>
                            </div>
                            
                        </div>

                        <div class="payment_paymentDetails">
                            <div class="payment_paymentDetails_item">
                                <p class="payment_paymentDetails_item_name">{{ product.title }}</p>
                                <p class="payment_paymentDetails_item_value">{{ product.currencySymbol }} {{ product.amount }}</p>
                            </div>

                            <div class="payment_paymentDetails_item">
                                <p class="payment_paymentDetails_item_description">{{ product.description }}</p>
                            </div>

                            <div v-if="product.currency == 'EUR' && !vatFee.isValid" class="payment_paymentDetails_item">
                                <p class="payment_paymentDetails_item_name">{{ translateWord('Imposto(VAT)') }}</p>
                                <p class="payment_paymentDetails_item_value">{{ product.currencySymbol }} {{ vatFee.amountWithoutCoupon }} <span style="font-size: 0.75em">( {{ vatFee.percentage }}% | {{ localeUser.country }} )</span></p>
                            </div>

                            <div class="payment_paymentDetails_item" v-if="hasCouponAndIsValid && !product.isAmountFirstMonth">
                                <p class="payment_paymentDetails_item_name">{{ translateWord('Desconto') }}</p>
                                <p class="payment_paymentDetails_item_value">- {{ product.currencySymbol }} {{ couponFormatAmount.toFixed(2) }}</p>
                            </div>

                            <template v-if="product.isAmountFirstMonth">
                                <div class="payment_paymentDetails_item">
                                    <p class="payment_paymentDetails_item_name">{{ translateWord('Primeiro mês') }}</p>
                                    <p class="payment_paymentDetails_item_value"> {{ product.currencySymbol }} {{ amountFirstMonth }}</p>
                                </div>
                            </template>

                            <div class="payment_paymentDetails_item payment_paymentDetails_itemBorderTop">
                                <p v-if="product.isAmountFirstMonth" class="payment_paymentDetails_item_name">{{ translateWord('Recorrência') }}</p>
                                <p v-else class="payment_paymentDetails_item_name">{{ translateWord('Total Estimado') }}</p>

                                <p v-if="hasCouponAndIsValid && !product.isAmountFirstMonth" class="payment_paymentDetails_item_value">{{ product.currencySymbol }} {{ parseFloat(parseFloat(product.amount - couponFormatAmount) + parseFloat(vatFee.amount)).toFixed(2)  }}</p>
                                <p v-else class="payment_paymentDetails_item_value">{{ product.currencySymbol }} {{ parseFloat(parseFloat(product.amount) + parseFloat(vatFee.amountWithoutCoupon)).toFixed(2) }}</p>
                            </div>
                            
                            <p class="payment_paymentDetails_item trialDays" v-if="product.isTrialDays">
                                {{ translateWord('O valor so sera debitado após os {0} dias de teste, caso não queira ser cobrado, cancele sua assinatura dentro deste período.', [product.trialDays]) }}
                            </p>

                            <p class="payment_paymentDetails_messageSecurity" v-if="paymentMethodSelected != 'pagSeguro' && paymentMethodSelected != 'boleto' && (!isFirstPaymentUpLine || !isUserFatherToReceiveByGateway) && !isPaymentDomain">
                                <template v-if="product.isTrialDays">
                                    <at-checkbox v-model="isCheckCouponFixedValue" :click="verifyActiveContinueStep()" class="payment_paymentDetails_checkboxCouponFixedValue">
                                        {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {1}, apos o período de {2} dias de teste. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo', [
                                        product.currencySymbol,
                                        product.amount,
                                        product.trialDays
                                        ]) }}
                                    </at-checkbox>
                                </template>

                                <template v-else-if="hasCouponAndIsValid && !product.isAmountFirstMonth">
                                    <at-checkbox v-model="isCheckCouponFixedValue" :click="verifyActiveContinueStep()" class="payment_paymentDetails_checkboxCouponFixedValue">
                                        {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {2}. e na recorrência o valor de {0} {1}. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo', [
                                        product.currencySymbol,
                                        product.amount,
                                        product.amountCoupon
                                        ]) }}
                                    </at-checkbox>
                                </template>

                                <template v-else-if="product.isAmountFirstMonth">
                                    <at-checkbox v-model="isCheckCouponFixedValue" :click="verifyActiveContinueStep()" class="payment_paymentDetails_checkboxCouponFixedValue">
                                        {{ translateWord('Entendo que o Primeiro pagamento do mês sera de {0} {1}, depois de 30 dias o valor de {2} {3} começa a ser debitado na recorrência', [
                                        product.currencySymbol,
                                        product.amountFirstMonth,
                                        product.currencySymbol,
                                        product.amount
                                        ]) }}
                                    </at-checkbox>
                                </template>


                                <template v-else>
                                    <at-checkbox v-model="isCheckCouponFixedValue" :click="verifyActiveContinueStep()" class="payment_paymentDetails_checkboxCouponFixedValue">
                                        {{ translateWord('Entendo que estou aderindo à plataforma Builderall e que no meu cartão será cobrado o valor de {0} {1}. Eu reconheço que posso cancelar a qualquer momento criando um tíquete ou entrando em contato com nossa equipe de suporte por chat ao vivo', [
                                        product.currencySymbol,
                                        product.amount
                                        ]) }}
                                    </at-checkbox>
                                </template>
                            </p>
    
                            <at-button v-if="['creditCard', 'sepa', 'myCards'].includes(paymentMethodSelected) && (!isFirstPaymentUpLine || !isUserFatherToReceiveByGateway)" @click.prevent="btnContinue" class="payment_paymentDetails_finish" type="primary" size="large">
                                <span v-if="!product.isTrialDays">{{ translateWord('FINALIZAR PAGAMENTO') }}</span>
                                <span v-if="product.isTrialDays">{{ translateWord('AUTORIZAR COBRANÇA') }}</span>
                            </at-button>
                            
                            <at-button v-else-if="paymentMethodSelected == 'paypal'" @click.prevent="btnContinue" class="payment_paymentDetails_finish paypal" type="primary">
                                <img src="../assets/paypal-button.png" alt="Paypal" title="Paypal">
                                {{ translateWord('Checkout') }}
                            </at-button>

                            <at-button v-else-if="paymentMethodSelected == 'pagSeguro'" @click.prevent="btnContinue" class="payment_paymentDetails_finish pagSeguro" type="primary">
                                <img src="../assets/pagseguro-logo.png" alt="PagSeguro" title="PagSeguro">
                                {{ translateWord('PAGAR COM PAGSEGURO') }}
                            </at-button>

                            <at-button v-else-if="paymentMethodSelected == 'boleto'" @click.prevent="btnContinue" class="payment_paymentDetails_finish pagSeguro" type="primary">
                                GERAR BOLETO
                            </at-button>

                            <div class="payment_paymentDetails_stamps">
                                <div class="payment_paymentDetails_stamps_item">
                                    <img src="../assets/imagem/shield2.svg" :alt="translateWord('Segurança')" :title="translateWord('Segurança')">
                                    <p class="payment_paymentDetails_stamps_item_desc">{{ translateWord('100% Seguro') }}</p>
                                </div>

                                <div class="payment_paymentDetails_stamps_item">
                                    <img src="../assets/imagem/security2.svg" :alt="translateWord('Certificado de SSL')" :title="translateWord('Certificado de SSL')">
                                    <p class="payment_paymentDetails_stamps_item_desc">{{ translateWord('Certificado de SSL') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
        </div>

        <div class="planOrFeeDetails_btnContinueResponsive">
            <at-button v-if="['creditCard', 'sepa', 'myCards'].includes(paymentMethodSelected) && (!isFirstPaymentUpLine || !isUserFatherToReceiveByGateway)" @click.prevent="btnContinue" class="btn_fullWidth" type="primary" size="large">
                <span v-if="!product.isTrialDays">{{ translateWord('FINALIZAR PAGAMENTO') }}</span>
                <span v-if="product.isTrialDays">{{ translateWord('AUTORIZAR COBRANÇA') }}</span>
            </at-button>
            
            <at-button v-else-if="paymentMethodSelected == 'paypal'" @click.prevent="btnContinue" class="paypal btn_fullWidth" type="primary">
                <img src="../assets/paypal-button.png" alt="Paypal" title="Paypal">
                {{ translateWord('Checkout') }}
            </at-button>

            <at-button v-else-if="paymentMethodSelected == 'pagSeguro'" @click.prevent="btnContinue" class="pagSeguro btn_fullWidth" type="primary">
                <img src="../assets/pagseguro-logo.png" alt="PagSeguro" title="PagSeguro">
                {{ translateWord('PAGAR COM PAGSEGURO') }}
            </at-button>

            <at-button v-else-if="paymentMethodSelected == 'boleto'" @click.prevent="btnContinue" class="boleto btn_fullWidth" type="primary">
                GERAR BOLETO
            </at-button>
        </div> -->
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'; 
import axios from 'axios';

import PixelAnalytics from '../assets/js/PixelAnalytics';

import Box from '../components/Box';
import Helper from '../assets/js/helper';
import TempRedirPlan from '../assets/js/TempRedirPlan';

import { cpf as CpfVerifier, cnpj as CnpjVerifier } from 'cpf-cnpj-validator';

export default {
    name: "Payment",
    inject: ['prevStep','nextStep'],
    data(){
        return {
            responseBuilderallGateway: null,
            responseBuilderallGatewayFuturePayment: null,
            disabledBtnContinue: true,
            isSendEventAddPaymentInfo: false,
            isCheckCouponFixedValue: false,
            couponAddUser: '',
            myCards: [],
            pagarme: {
                isVisible: false,
                tokenCard: '',
                card: {
                    number: '',
                    holderName: '',
                    expiryMonth: '',
                    expiryYear: '',
                    cvc: '',
                    validate: '',
                    cpfOrCnpj: '',
                    phone: {
                        areaCodeAndNumber: '',
                        areaCode: '',
                        number: ''
                    }
                },
            },
            stripe: {
                isVisible: false,
                instance: null,
                elements: null,
                card: null,
                tokenCard: null,
                holderName: '',
                setupIntent: null,
                cardSetup: null,
                sourceToken: null,
                fieldsValidators: {
                    holderName: {
                        error: false,
                        test: false
                    }
                }
            },
            adyen: {
                isVisible: false,
                isThreeSecureUrl: this.$route.query.hasOwnProperty('threeSecure'),
                threeSecureIdentify: this.$route.query.hasOwnProperty('threeSecure') ? this.$route.query.identify : false,
                isPaymentThreeSecure: localStorage.getItem('vatFee') && localStorage.getItem('localeUser'),
                card: {
                    number: '',
                    holderName: '',
                    expiryMonth: '',
                    expiryYear: '',
                    cvc: '',
                    generationtime: new Date().toJSON()
                },
                sepa: {
                    ownerName: '',
                    ibanNumber: '',
                    fieldsValidators: {
                        ownerName: {
                            error: false,
                            test: false
                        },
                        ibanNumber: {
                            error: false,
                            test: false
                        }
                    }
                },
                validate: '',
                cardAdyenEncrypt: null,
                fieldsValidators: {
                    number: {
                        error: false,
                        test: false
                    },
                    holderName: {
                        error: false,
                        test: false
                    },
                    validate: {
                        error: false,
                        test: false
                    },
                    cvc: {
                        error: false,
                        test: false
                    }
                },
                threeDS:{
                    issuerUrl: '',
                    md: '',
                    paRequest: '',
                    termUrl: ''
                }
            },
            address: {
                zipCode: '',
                city: '',
                street: '',
                country: '',
                stateCode: '',
                district: '',
                number: '',
                complement: '',
                fieldsValidators: {
                    zipCode: {
                        error: false,
                        test: false
                    },
                    city: {
                        error: false,
                        test: false
                    },
                    street: {
                        error: false,
                        test: false
                    },
                    country: {
                        error: false,
                        test: false
                    }
                }
            }
        }
    },
    computed: {
        ...mapState([
            'user',
            'userFather',
            'localeUser',
            'endpointGateway',
            'endpointOffice',
            'coupon',
            'fatura',
            'invoicePlanToActive',
            'vatFee',
            'adyenKeys',
            'totalPossibleFraud',
            'paymentMethodSelected',
            'paymentMethodEnabled',
            'isPlan',
            'fakeAmountPlanOrFee',
            'isPaymentOnlyPaypal',
            'isFirstPaymentUpLine',
            'isUserFatherToReceiveByGateway',
            'isPaymentDomain',
            'isPaymentStorage',
            'isPaymentDomainExtraConnection',
            'isPaymentCountryPrice',
            'planCountryPrice',
            'myCardSelectedPayment',
            'stepAction',
            'isChargedAmount',
            'isPauseAccount',
            'devMode',
            'tokenCheckout'
        ]),
        ...mapGetters([
            'product',
            'hasCoupon',
            'hasCouponAndIsValid',
            'urlOfficeUser',
            'translateWord',
            'reasonCouponInvalid',
            'isPlanToActive',
            'getSlug',
            'isCoupon100Percent',
            'isCouponPeriodTime'
        ]),
        couponFormatAmount(){
            return Helper.calcDiscount(this.product.amount, this.coupon.value, this.coupon.type)
        },
        amountDiscount(){
            return parseFloat(parseFloat(this.product.amount - this.couponFormatAmount) + parseFloat(this.vatFee.amount)).toFixed(2)
        },
        amountFirstMonth(){
            let amountPlusVat = parseFloat(this.product.amountFirstMonth);
            
            if(this.vatFee.percentage > 0){
                return ((amountPlusVat * this.vatFee.percentage / 100) + amountPlusVat).toFixed(2);
            }
            
            return amountPlusVat.toFixed(2);
        }
    },
    watch: {
        'adyen.validate': function(value){
            if(value.length == 5){
                let year = (new Date()).getFullYear().toString();

                this.adyen.card.expiryMonth = value.substr(0, 2);
                this.adyen.card.expiryYear = `${year.substr(0,2)}${value.substr(3, 4)}`;
            }
        },
        'pagarme.card.validate': function(value){
            if(value.length == 5){
                let year = (new Date()).getFullYear().toString();

                this.pagarme.card.expiryMonth = value.substr(0, 2);
                this.pagarme.card.expiryYear = value.substr(3, 4);
            }
        },
        'pagarme.card.phone.areaCodeAndNumber': function(value){
            if(value.length >= 2){
                value = value.replace(/\D/gi, '');

                this.pagarme.card.phone.areaCode = value.substr(0, 2);
                this.pagarme.card.phone.number = value.substr(2);
            }
        },
        stepAction: function(value){
            if(value == 'prev') this.prevStepWithEmit();

            this.changeStepAction(true);
        }
    },
    async created(){
        if(['dev', 'test'].includes(Helper.getEnvs().env)){
            this.adyen.card.number = '4111111111111111'; //4917610000000000 - 4111111111111111
            this.adyen.card.holderName = 'John Smith';
            this.adyen.validate = '03/30';
            this.adyen.card.cvc = '737';
            
            this.adyen.sepa.ownerName = 'B. Klaassen';
            this.adyen.sepa.ibanNumber = 'NL36TEST0236169114';

            this.pagarme.card.number = '4000000000000028';
            this.pagarme.card.holderName = 'Philipinho Gomes';
            this.pagarme.card.validate = '01/30';
            this.pagarme.card.cvc = '737';
            this.pagarme.card.phone.areaCodeAndNumber = '31999999999';
            this.pagarme.card.cpfOrCnpj = '18839195092';
            
            if(this.product.currency == 'BRL'){
                this.address.zipCode = '31565220';
                this.searchLocaleZipCode();
            }

            console.group('Lista de cartões');
            console.info('Requer autenticação apenas uma vez (SCA): 4000 0025 0000 3155');
            console.info('Requer autenticação forte na recorrencia (SCA): 4000 0027 6000 3184');
            console.log('Sem autenticação: 4111 1111 1111 1111');
            console.log('Sem autenticação: 4242 4242 4242 4242');
            console.groupEnd();
        }

        this.verifyCurrentPlanUser();

        if(this.user.isLogged){
            let typeRedir = Helper.redirUserPlanTrialOrPromotional(this.getSlug, this.user, this.userFather);

            if(typeRedir == 'tempRedir') return window.location.href = TempRedirPlan.getUrlRedir();
            if(typeRedir == 'office') return window.location.href = this.urlOfficeUser;

            if(this.user.credit_cards.length != 0){
                this.myCards = [];
                this.myCards = [...this.user.credit_cards];
                this.myCards = this.myCards.map(card => ({...card, selected: false}));

                this.myCards[0].selected = true;
            }
        }

        this.paymentMethod(this.paymentMethodSelected);

        if(this.product.currency == 'BRL' && !this.hasCouponAndIsValid || this.product.isAmountFirstMonth || this.isFirstPaymentUpLine) this.disabledBtnContinue = false;
        
        this.isCheckCouponFixedValue = true;
        
        PixelAnalytics.addDataLayer('StepPayment', {
            name: this.product.title,
            currency: this.product.currency,
            amount: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount
        });

        this.userFatherToReceiveByGateway(false);

        PixelAnalytics.addDataLayer('SecondStep');

        if(PixelAnalytics.pixelEnabled.facebookPixel){
            PixelAnalytics.createEventPixel('fb', 'SecondStep');
        }

        if(PixelAnalytics.pixelEnabled.googleAnalytics){
            PixelAnalytics.createEventPixel('ga', null, {
                hitType: 'event',
                location: location.href,
                page: location.pathname,
                eventCategory: 'SecondStep'
            });
        }
        
        if(this.hasCoupon) this.couponAddUser = this.coupon.code;

        if(this.hasCouponAndIsValid && this.product.isTrialDays) this.enabledPaypal(false);

        if(this.isCoupon100Percent || this.isCouponPeriodTime){
            this.enabledPaypal(false);
            this.enabledBoleto(false);
        }

        if(this.planCountryPrice.hasOwnProperty('annual') && this.product.isBuyAnnual){
            this.enabledPaypal(false);
        }

        this.addNoteVatFee();

        //Verifica se é o retorno da Adyen com pagamento 3D Secure
        if(this.adyen.isThreeSecureUrl && this.adyen.threeSecureIdentify && this.adyen.isPaymentThreeSecure){
            this.enabledFullScreenLoading({
                status: true,
                text: this.translateWord('Verificação final para finalizar o pagamento')
            });

            this.addAllData({
                stateName: 'localeUser',
                data: JSON.parse(localStorage.getItem('localeUser'))
            });

            this.addAllData({
                stateName: 'vatFee',
                data: JSON.parse(localStorage.getItem('vatFee'))
            });

            localStorage.removeItem('vatFee');
            localStorage.removeItem('localeUser');

            this.finalizePaymentAdyen();
        }
    },
    methods: {
        ...mapMutations([
            'enabledFullScreenLoading',
            'incrementPossibleFraud',
            'userFatherToReceiveByGateway',
            'addDataNote',
            'setBoletoDetails',
            'addAllData',
            'selectPaymentMethod',
            'changeStepAction',
            'enabledPaypal',
            'enabledBoleto',
            'setPixDetails',
            'resetVat',
            'setBgResponse'
        ]),
        ...mapActions([
            'validateCoupon',
            'updateNoteInvoice',
            'verifyCurrentPlanUser'
        ]),
        selectedMyCard(indexSelected){
            this.myCards = this.myCards.map((card, index) => {
                card.selected = false;

                if(indexSelected == index) card.selected = true;

                return card;
            })
        },
        paymentMethod(payment){
            this.selectPaymentMethod(payment);

            //Stripe
            if(['creditCard'].includes(this.paymentMethodSelected) && (!['EUR', 'BRL'].includes(this.product.currency) || this.isPaymentCountryPrice)){
                this.stripe.isVisible = true;

                this.adyen.isVisible = false;
                this.pagarme.isVisible = false;

                this.mountedStripe();
            }

            //Adyen
            if(['creditCard'].includes(this.paymentMethodSelected) && ['EUR'].includes(this.product.currency) && !this.isFirstPaymentUpLine){
                this.adyen.isVisible = true;

                this.pagarme.isVisible = false;
                this.stripe.isVisible = false;
            }

            //Pagar.me
            if(['creditCard', 'boleto', 'pix'].includes(this.paymentMethodSelected) && ['BRL'].includes(this.product.currency) && !this.isFirstPaymentUpLine){
                this.pagarme.isVisible = true;

                this.stripe.isVisible = false;
                this.adyen.isVisible = false;
            }

            if(this.paymentMethodSelected == 'creditCard') {
                if(this.product.isTheCurrency.eur && localStorage.getItem('vatFee')){
                    this.addAllData({
                        stateName: 'vatFee',
                        data: JSON.parse(localStorage.getItem('vatFee'))
                    });

                    localStorage.removeItem('vatFee');
                }

                PixelAnalytics.addDataLayer('paymentBySelectedCreditCard');
            }
            if(this.paymentMethodSelected == 'sepa') {
                PixelAnalytics.addDataLayer('paymentBySelectedSepa');
            }

            //Paypal
            if(this.paymentMethodSelected == 'paypal') {
                this.pagarme.isVisible = false;
                this.stripe.isVisible = false;
                this.adyen.isVisible = false;

                if(this.product.isTheCurrency.eur){
                    localStorage.setItem('vatFee', JSON.stringify(this.vatFee));

                    this.addDataNote({nota: {vat_details: []}});

                    this.resetVat();
                }

                if(this.product.first_payment_to_upline && this.userFather.receivement_gateways != null && this.product.currency != 'EUR' && this.userFather.receivement_gateways.hasOwnProperty('paypal')){
                    this.userFatherToReceiveByGateway(true);
                }

                PixelAnalytics.addDataLayer('paymentBySelectedPaypal');
            }

            //PagSeguro
            if(this.paymentMethodSelected == 'pagSeguro') {
                this.pagarme.isVisible = false;
                this.stripe.isVisible = false;
                this.adyen.isVisible = false;

                if(this.product.first_payment_to_upline && this.userFather.receivement_gateways != null && this.product.currency != 'EUR' && this.userFather.receivement_gateways.hasOwnProperty('pagseguro')){
                    this.userFatherToReceiveByGateway(true);
                }

                PixelAnalytics.addDataLayer('paymentBySelectedPagSeguro');
            }

            //Adyen
            if(this.paymentMethodSelected == 'myCards'){
                this.adyen.isVisible = false;
                this.pagarme.isVisible = false;
                this.stripe.isVisible = false;
            }
        },
        async validCoupon(){
            await this.validateCoupon({
              couponUser: this.couponAddUser,
              isLoadingCheckout: false
            });
        },
        async btnContinue(){
            if(!this.isSendEventAddPaymentInfo){
                PixelAnalytics.createEventPixel('fb', 'AddPaymentInfo', {
                    name: this.product.title,
                    value: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount,
                    currency: this.product.currency
                });

                this.isSendEventAddPaymentInfo = true;
            }

            if(this.stripe.isVisible) {
                this.enabledFullScreenLoading({ status: true, text: !this.product.isTrialDays ? this.translateWord('Efetuando pagamento, aguarde!') : this.translateWord('Autorizando pagamento, aguarde!')});

                this.updateNotesBeforePayment();
                this.finalizePaymentStripe();
                return;
            }

            if(this.adyen.isVisible && this.paymentMethodSelected == 'creditCard'){
                this.enabledFullScreenLoading({ status: true, text: !this.product.isTrialDays ? this.translateWord('Efetuando pagamento, aguarde!') : this.translateWord('Autorizando pagamento, aguarde!')});

                this.updateNotesBeforePayment();
                this.finalizePaymentAdyen();
                return;
            }

            if(this.adyen.isVisible && this.paymentMethodSelected == 'sepa'){
                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Efetuando pagamento, aguarde!') });

                this.updateNotesBeforePayment();
                this.finalizePaymentAdyenSepa();
                return;
            }

            if(this.pagarme.isVisible && this.paymentMethodSelected == 'creditCard'){
                if(!await this.pagarmeCreateToken()) return;

                if(!this.verifyAditionalInfo()) return;

                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Efetuando pagamento, aguarde!') });

                this.updateNotesBeforePayment();
                this.finalizePaymentPagarme();
                return;
            }

            if(this.pagarme.isVisible && this.paymentMethodSelected == 'boleto'){
                if(!this.verifyAditionalInfo()) return;

                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Gerando boleto aguarde!') });

                this.updateNotesBeforePayment();
                this.finalizePaymentPagarmeBoleto();
                return;
            }

            if(this.pagarme.isVisible && this.paymentMethodSelected == 'pix'){
                this.enabledFullScreenLoading({ status: true, text: 'Gerando PIX aguarde!' });

                this.updateNotesBeforePayment();
                this.finalizePaymentPagarmePix();
                return;
            }
            
            if(this.paymentMethodSelected == 'paypal'){
                /*if(this.verifyCountryPricePayPaypal()){
                    this.enabledFullScreenLoading({ status: true });
                    return;
                }*/

                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Você esta sendo redirecionado para o paypal') });

                await this.updateNotesBeforePayment();
                this.finalizePaymentPaypal();
                return;
            }

            if(['BRL'].includes(this.product.currency) && this.paymentMethodSelected == 'pagSeguro' || this.isFirstPaymentUpLine){
                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Abrindo popup do Pagseguro, aguarde!') });

                this.updateNotesBeforePayment();
                this.finalizePaymentPagseguro();
                return;
            }

            if(this.paymentMethodSelected == 'myCards') {
                this.enabledFullScreenLoading({ status: true, text: this.translateWord('Efetuando pagamento com seu cartão vinculado') });

                this.updateNotesBeforePayment();
                this.finalizePaymentMyCard();
                return;
            }
        },
        async updateNotesBeforePayment(){
            let amountWithoutFee = null;

            if(this.paymentMethodSelected == 'paypal' && this.product.isTheCurrency.eur){
                amountWithoutFee = this.product.amountCoupon ? this.product.amountCoupon : this.product.amount;

                if(isNaN(amountWithoutFee)) this.$logs.post('', ['isNaN no amountWithoutFee.', {
                    product: this.product.title,
                    amountCoupon: this.product.amountCoupon,
                    amountProduct: this.product.amount,
                    amountFirstMonth: this.product.amountFirstMonth,
                    idUser: this.user.user_identifier,
                    invoice: this.fatura.invoice.id,
                }]);

                this.addDataNote({
                    nota: {
                        amount_without_fee: !isNaN(amountWithoutFee) ? amountWithoutFee : this.product.amount
                    }
                });
            }

            await this.updateNoteInvoice();
        },
        async createFuturePaymentAfterPaymentSuccess(){
            let businnesGateway = null;

            if(this.stripe.isVisible) businnesGateway = 'stripe';
            if(this.adyen.isVisible) businnesGateway = 'adyen';
            if(this.pagarme.isVisible) businnesGateway = 'pagarme';

            let data = {
                application: 'OFFICE',
                business_gateway: businnesGateway,
                gateway_identifier: this.responseBuilderallGateway.gateway.gateway_identifier,
                is_recurring: true,
                interval: this.isPlan ? this.product.interval : this.product.plan_to_active.interval,
                product: this.isPlan ? this.product.title : this.product.plan_to_active.title,
                period: 'MONTH',
                amount: {
                    currency: this.isPlan ? this.product.currency : this.product.plan_to_active.currency,
                    value: this.isPlan ? this.product.amount : this.product.plan_to_active.amount
                },
                my_data: {
                    vat_details: {},
                    address: {},
                    coupon: {},
                    ip: this.localeUser.ip
                },
                notification_url: this.isPlan ? this.product.webhooks : this.product.plan_to_active.webhooks
            };
            
            if(this.product.isTrialDays){
                data['trial_days'] = this.product.trialDays;
                data.my_data.office_fatura = this.fatura.invoice.id;
            }
            
            if(this.isPlanToActive){
                data.my_data.office_fatura = this.invoicePlanToActive;
                data.my_data.parent_invoice_fee = this.fatura.invoice.id;
            }

            if(this.product.currency == 'EUR' && !this.localeUser.isDutch && this.vatFee.isValid){
                data.my_data.vat_details.number_completed = this.vatFee.vatNumberCompleted;
                data.my_data.vat_details.number = this.vatFee.vatNumber;
                data.my_data.vat_details.country = this.vatFee.vatCountry;
                data.my_data.vat_details.company_name = this.vatFee.vatCompanyName;
                data.my_data.vat_details.company_address = this.vatFee.vatCompanyAddress;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se é da holanda, ja é para cobrar o VAT, mesmo tendo ou nao tendo VAT
            if(this.product.currency == 'EUR' && this.localeUser.isDutch || this.product.currency == 'EUR' && !this.localeUser.isDutch && !this.vatFee.isValid){
                data.fee = this.vatFee.percentage;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se usuario for da Europa com Regiao de Canarias
            if(this.localeUser.isRegionCanarias){
                data.my_data.is_region_canarias = true;
            }

            if(Object.keys(data.my_data.vat_details).length == 0){
                data.my_data.vat_details = null;
            }

            if(['EUR', 'BRL'].includes(this.product.currency)){
                data.my_data.address.zipCode = this.address.zipCode;
                data.my_data.address.city = this.address.city;
                data.my_data.address.street = this.address.street;
                data.my_data.address.country = this.address.country;

                if(this.product.currency == 'BRL') data.my_data.address.state_code = this.address.stateCode;
            }

            if(!['EUR', 'BRL'].includes(this.product.currency)) data.my_data.address = null;

            //Verificar e adicionar disconto
            if(this.hasCouponAndIsValid){
                data.discount = {
                    code: this.coupon.code,
                    value: this.coupon.type == 'FIXED_VALUE' ? parseFloat(Helper.formatValueDiscount(this.product.amount, this.coupon.value, this.coupon.type)).toFixed(2) : this.coupon.value,
                    type: this.coupon.type == 'FIXED_VALUE' ? 'DISCOUNT_FIXED' : 'PERCENTAGE',
                    period: this.coupon.apply_only_first_month == 1 ? 'FIRST_MONTH' : 'FOREVER'
                };

                data.my_data.coupon.id = this.coupon.id;
                data.my_data.coupon.code = this.coupon.code;
            }

            //Verifica se é pagamento por country price
            if(this.isPaymentCountryPrice){
                data.my_data.country_price = this.planCountryPrice;
            }

            if(this.isCoupon100Percent) delete data.my_data;
            
            this.responseBuilderallGatewayFuturePayment = (await this.$http.post(this.endpointGateway.futurePayment, data)).data;
        },
        createDataForSend(){
            let data = {
                application: 'OFFICE',
                product: this.product.title,
                country_code: this.localeUser.country,
                name: this.user.name,
                id_user: this.user.cryptId,
                email: this.user.email,
                address: {},
                my_data: {
                    office_fatura: this.fatura.invoice.id,
                    vat_details: {},
                    address: {},
                    coupon: {},
                    ip: this.localeUser.ip
                },
                amount: {
                    currency: this.product.currency,
                    value: this.product.amount
                },
                notification_url: this.product.webhooks,
                token_checkout: this.tokenCheckout
            };

            if(this.pagarme.isVisible){
                if(this.paymentMethodSelected == 'creditCard') data['token_card'] = this.pagarme.tokenCard;

                data['phone'] = {
                    areaCode: this.pagarme.card.phone.areaCode,
                    number: this.pagarme.card.phone.number
                };

                data['cpf_or_cnpj'] = this.pagarme.card.cpfOrCnpj;

                data.address['cep'] = this.address.zipCode;
                data.address['state_code'] = this.address.stateCode;
                data.address['city'] = this.address.city;
                data.address['street'] = `${this.address.number}, ${this.address.street}, ${this.address.district}`;
                data.address['complement'] = this.address.complement;
                data.address['country'] = this.address.country;
            }

            if(this.stripe.isVisible){
                data['token_card'] = this.stripe.cardSetup.setupIntent.payment_method;
                data['source_token'] = this.stripe.sourceToken;
            }
            
            if(this.adyen.isVisible && this.paymentMethodSelected == 'creditCard'){
                data['card-encrypt'] = this.adyen.cardAdyenEncrypt;
                data['browserInfo'] = {
                    userAgent: navigator.userAgent,
                    lang: navigator.language,
                    colorDepth: screen.colorDepth,
                    screenHeight: screen.height,
                    screenWidth: screen.width,
                    timezoneOffset: (new Date).getTimezoneOffset()
                }
            }
            
            if(this.adyen.isVisible && this.paymentMethodSelected == 'sepa'){
                data['owner_name'] = this.adyen.sepa.ownerName;
                data['iban_number'] = this.adyen.sepa.ibanNumber;
            }

            if(this.product.currency == 'EUR' && !this.localeUser.isDutch && this.vatFee.isValid){
                data.my_data.vat_details.number_completed = this.vatFee.vatNumberCompleted;
                data.my_data.vat_details.number = this.vatFee.vatNumber;
                data.my_data.vat_details.country = this.vatFee.vatCountry;
                data.my_data.vat_details.company_name = this.vatFee.vatCompanyName;
                data.my_data.vat_details.company_address = this.vatFee.vatCompanyAddress;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se é da holanda, ja é para cobrar o VAT, mesmo tendo ou nao tendo VAT
            if(this.product.currency == 'EUR' && this.localeUser.isDutch || this.product.currency == 'EUR' && !this.localeUser.isDutch && !this.vatFee.isValid){
                data.fee = this.vatFee.percentage;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se usuario for da Europa com Regiao de Canarias
            if(this.localeUser.isRegionCanarias){
                data.my_data.is_region_canarias = true;
            }

            if(Object.keys(data.my_data.vat_details).length == 0){
                data.my_data.vat_details = null;
            }

            if(['EUR', 'BRL'].includes(this.product.currency)){
                data.my_data.address.zipCode = this.address.zipCode;
                data.my_data.address.city = this.address.city;
                data.my_data.address.street = this.address.street;
                data.my_data.address.country = this.address.country;

                if(this.product.currency == 'BRL') data.my_data.address.state_code = this.address.stateCode;
            }

            if(!['EUR', 'BRL'].includes(this.product.currency)) data.my_data.address = null;


            if(this.isPlan){
                data.is_recurring = true;
                data.interval = this.product.interval;
                data.period = 'MONTH';
            }

            //Verificar e adicionar disconto
            if(this.hasCouponAndIsValid){
                data.discount = {
                    code: this.coupon.code,
                    value: this.coupon.type == 'FIXED_VALUE' ? parseFloat(Helper.formatValueDiscount(this.product.amount, this.coupon.value, this.coupon.type)).toFixed(2) : this.coupon.value,
                    type: this.coupon.type == 'FIXED_VALUE' ? 'DISCOUNT_FIXED' : 'PERCENTAGE',
                    period: this.coupon.period,
                    time: this.coupon.periodTime
                }
            }

            if(this.product.isAmountFirstMonth){
                data['amount']['first_month'] = this.product.amountFirstMonth;
            }

            //TEMP Webnar 27 de dezembro
            if(['3156', '3025'].includes(this.product.id)){
                if(this.product.isAmountFirstMonth){
                    data['amount']['first_month'] = this.product.amountFirstMonth;
                }
            }

            if(this.product.isTrialDays || this.isCoupon100Percent){
                data['first_payment_without_charge'] = true;
            }

            if(this.isPlanToActive){
                data.my_data.invoice_plan_to_active = this.invoicePlanToActive;
            }

            if(this.hasCouponAndIsValid){
                data.my_data.coupon.id = this.coupon.id;
                data.my_data.coupon.code = this.coupon.code;
            }

            if(this.isPauseAccount){
                data.my_data['pause_account'] = true;
            }

            //Verifica se é pagamento por country price
            if(this.isPaymentCountryPrice){
                data.my_data.country_price = this.planCountryPrice;

                if(this.planCountryPrice.hasOwnProperty('annual') && this.product.isBuyAnnual){
                    data.my_data.country_price.is_payment_annual = true;
                }

            }

            return data;
        },
        createDataPaymentMyCard(){
            let hashToken = this.myCards.filter(card => card.selected)[0].token;

            let data = {
                application: 'OFFICE',
                product: this.product.title,
                token: hashToken,
                my_data: {
                    office_fatura: this.fatura.invoice.id,
                    vat_details: {},
                    address: {},
                    coupon: {},
                    ip: this.localeUser.ip,
                    payment_type: 'one_click'
                },
                amount: {
                    value: this.product.amount
                },
                notification_url: this.product.webhooks
            };

            if(this.product.currency == 'EUR' && !this.localeUser.isDutch && this.vatFee.isValid){
                data.my_data.vat_details.number_completed = this.vatFee.vatNumberCompleted;
                data.my_data.vat_details.number = this.vatFee.vatNumber;
                data.my_data.vat_details.country = this.vatFee.vatCountry;
                data.my_data.vat_details.company_name = this.vatFee.vatCompanyName;
                data.my_data.vat_details.company_address = this.vatFee.vatCompanyAddress;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se é da holanda, ja é para cobrar o VAT, mesmo tendo ou nao tendo VAT
            if(this.product.currency == 'EUR' && this.localeUser.isDutch || this.product.currency == 'EUR' && !this.localeUser.isDutch && !this.vatFee.isValid){
                data.fee = this.vatFee.percentage;
                data.my_data.vat_details.fee = this.vatFee.percentage;
            }

            //Se usuario for da Europa com Regiao de Canarias
            if(this.localeUser.isRegionCanarias){
                data.my_data.is_region_canarias = true;
            }

            if(Object.keys(data.my_data.vat_details).length == 0){
                data.my_data.vat_details = null;
            }

            if(this.product.currency == 'EUR'){
                data.my_data.address.zipCode = this.address.zipCode;
                data.my_data.address.city = this.address.city;
                data.my_data.address.street = this.address.street;
                data.my_data.address.country = this.address.country;
            }

            if(this.product.currency != 'EUR') data.my_data.address = null;


            if(this.isPlan){
                data.is_recurring = true;
                data.interval = this.product.interval;
                data.period = 'MONTH';
            }

            //Verificar e adicionar disconto
            if(this.hasCouponAndIsValid){
                data.discount = {
                    code: this.coupon.code,
                    value: this.coupon.type == 'FIXED_VALUE' ? parseFloat(Helper.formatValueDiscount(this.product.amount, this.coupon.value, this.coupon.type)).toFixed(2) : this.coupon.value,
                    type: this.coupon.type == 'FIXED_VALUE' ? 'DISCOUNT_FIXED' : 'PERCENTAGE',
                    period: this.coupon.period,
                    time: this.coupon.periodTime
                }
            }

            if(this.product.isAmountFirstMonth){
                data['amount']['first_month'] = this.product.amountFirstMonth;
            }

            if(this.product.isTrialDays){
                data['trial_days'] = this.product.trialDays;
            }

            if(this.hasCouponAndIsValid){
                data.my_data.coupon.id = this.coupon.id;
                data.my_data.coupon.code = this.coupon.code;
            }

            if(this.isPauseAccount){
                data.my_data['pause_account'] = true;
            }

            //Verifica se é pagamento por country price
            if(this.isPaymentCountryPrice){
                data.my_data.country_price = this.planCountryPrice;

                if(this.planCountryPrice.hasOwnProperty('annual') && this.product.isBuyAnnual){
                    data.my_data.country_price.is_payment_annual = true;
                }

            }

            return data;
        },
        /**
         *  Caso o gateway tiver a seguraça 3DS. Funcionalidade para redicionar o usuario para finalizar a compra de forma segura no site do emissor do cartão.
         */
        paymentRedirectUser3Ds(gateway, data){
            localStorage.setItem('canSkipStep', true);
            localStorage.setItem('redirectUrlAfterPayment', this.product.redirect);
            localStorage.setItem('vatFee', JSON.stringify(this.vatFee));
            localStorage.setItem('localeUser', JSON.stringify(this.localeUser));

            this.enabledFullScreenLoading({
                status: true,
                text: this.translateWord('Voce sera redirecionado para finalizar o pagamento no site do emissor do seu cartão!'),
            });

            if(gateway == 'adyen'){
                this.adyen.threeDS.issuerUrl = data.issuerUrl;
                this.adyen.threeDS.md = data.md;
                this.adyen.threeDS.paRequest = data.paRequest;
                this.adyen.threeDS.termUrl = data.termUrl;

                //Tempo para que os dados seja inseridos no DOM.
                setTimeout(() => this.$refs.threeDSAdyen.click() , 2000)
            }
        },
        async paymentSuccess(){
            if(this.isPlanToActive) await this.createFuturePaymentAfterPaymentSuccess();

            this.nextStepWithEmit();
        },
        paymentRefused(){
            this.enabledFullScreenLoading({ status: false})

            if(this.responseBuilderallGateway.gateway.status == 'Error' && this.responseBuilderallGateway.gateway.message == 'payment_refused' && this.responseBuilderallGateway.gateway.hasOwnProperty('reason')){
                return this.paymentError(this.responseBuilderallGateway.gateway.message, this.responseBuilderallGateway.gateway.reason, this.responseBuilderallGateway.gateway.type);
            }

            return this.$Notify.info({
                title: this.translateWord('Erro no analise'),
                message: this.responseBuilderallGateway.message,
                duration: 0
            });
        },
        paymentError(message, reason, type){

            if(['pagarme_boleto_single'].includes(type)){
                return this.$Notify.error({
                    title: this.translateWord('Erro ao gerar boleto'),
                    message: this.translateWord('Não foi possivel gerar o seu boleto. Verifique se o seu CPF/CNPJ, telefone e endereço esta preenchido corretamente.'),
                    duration: 0
                });
            }

            if(this.responseBuilderallGateway.gateway.type == 'pagarme_multiple'){
                return this.$Notify.error({
                    title: this.translateWord('Erro ao efetuar pagamento'),
                    message: this.responseBuilderallGateway.gateway.reason,
                    duration: 0
                });
            }

            if(reason == 'unable_to_determine_variant'){
                return this.$Notify.warning({
                    message: this.translateWord('Unable to determine variant'),
                    duration: 0
                });
            }

            if(reason == 'invalid_card_number'){
                return this.$Notify.warning({
                    message: this.translateWord('O Numéro do cartão é inválido'),
                    duration: 0
                });
            }

            if(reason == 'card_holder_missing'){
                return this.$Notify.warning({
                    message: this.translateWord('O Titular do cartão esta incorreto'),
                    duration: 0
                });
            }

            if(reason == 'expiry_date_invalid'){
                return this.$Notify.warning({
                    message: this.translateWord('Data de validade inválida'),
                    duration: 0
                });
            }

            if(reason == 'cvc_is_not_the_right_length'){
                return this.$Notify.warning({
                    message: this.translateWord('O CVC não tem o tamanho certo'),
                    duration: 0
                });
            }

            if(reason == 'not_supported'){
                return this.$Notify.warning({
                    message: this.translateWord('Cartão de pagamento não suportado, favor tente com outro cartão'),
                    duration: 0
                });
            }

            if(reason == 'cvc_declined'){
                return this.$Notify.warning({
                    message: this.translateWord('Código de segurança do cartão é invalido'),
                    duration: 0
                });
            }

            if(reason == 'payment_details_are_not_supported'){
                return this.$Notify.warning({
                    message: this.translateWord('Bandeira de cartão não disponivel para pagamento'),
                    duration: 0
                });
            }

            if(reason == 'possible_fraud'){
                this.incrementPossibleFraud();

                if(this.totalPossibleFraud > Helper.possibleFraudAttempts){
                    return this.blockUserReasonFraud(response.data);
                }

                return this.$Notify.error({
                    message: this.translateWord('Identificamos que o cartão utilizado está sob suspeita de FRAUDE, caso continue utilizando-o, sua conta será bloqueada.'),
                    duration: 0
                });
            }

            if(reason == 'user_block_blacklist'){
                return this.$Notify.warning({
                    message: this.translateWord('Você esta na blacklist por identificarmos como usuario fraudulento. Caso não seja entre em contato com suporte.'),
                    duration: 0
                });
            }

            if(message == 'payment_refused'){
                return this.$Notify.info({
                    message: this.translateWord('Seu pagamento foi recussado, verifique os dados do cartão corretamente!'),
                    duration: 0
                });
            }
        },
        blockUserReasonFraud(gateway){
            let date = new Date();
            let data = {
                invoice_id: this.fatura.invoice.id,
                cryptId: this.usuario.cryptId,
                reason: 'fraud',
                event_date: date.toISOString(),
                amount: {
                    currency: gateway.gateway.currency,
                    value: gateway.gateway.amount
                }
            };

            this.$httpOfficeAdmin.post(this.endpointOffice.FRAUDE, data).then(response => {
                if(response.data.status == 'success'){
                    window.location.href = this.urlOfficeUser;
                }
            });
        },
        async mountedStripe(){
            let publicKey = ['prod'].includes(Helper.getEnvs().env)
                ? 'pk_live_bazKLrAu8IndMZPY0AmzJjFT'
                : 'pk_test_QnTgAa0SQy9J1rGdambcpWAd';
            
            this.stripe.instance = Stripe(publicKey, {
                locale: this.localeUserCheckoutStripe()
            });
            this.stripe.setupIntent = await this.$http.post(this.endpointGateway.setupIntentStripe, {
                application: 'OFFICE'
            }).then(res => res.data.gateway);

            // Create an instance of Elements.
            this.stripe.elements = this.stripe.instance.elements();

            // Create an instance of the card Element.
            this.stripe.card = this.stripe.elements.create('card', {style: {
                    base: {
                        color: '#32325d',
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4'
                        }
                    },
                    invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a'
                    }
                }});

            // Add an instance of the card Element into the `card-element` <div>.
            this.stripe.card.mount('#stripe-element');

            // Handle real-time validation errors from the card Element.
            this.stripe.card.addEventListener('change', event => {
                if (event.error) {
                    return this.$Notify.info({
                        title: this.translateWord('Erro no cartão'),
                        message: event.error.message,
                        duration: 0
                    });
                }
            });
        },
        localeUserCheckoutStripe(){
            return 'en';
            
            /*let locale = this.localeUser.country.toLowerCase();
            
            if(locale == 'br') return 'pt-BR';  
            
            return locale;*/
        },
        async pagarmeCreateToken(){
            let key = Helper.getEnvs().env == 'prod' ? 'pk_rqGdR00SxTZ8XDlL' : 'pk_test_WA01Q0JCBUNyGV9r';

            try{
                let tokenCard = (await axios.post(`https://api.pagar.me/core/v5/tokens?appId=${key}`, {
                    card: {
                        number: this.pagarme.card.number.split(' ').join(''),
                        holder_name: this.pagarme.card.holderName,
                        exp_month: this.pagarme.card.expiryMonth,
                        exp_year: this.pagarme.card.expiryYear,
                        cvv: this.pagarme.card.cvc
                    },
                    type: 'card'
                })).data;

                this.pagarme.tokenCard = tokenCard.id;

                return true;
            }catch(exception){
                let error = Object.entries(exception.response.data.errors)[0][0];

                if(error == 'request.card.holder_name'){
                    this.$Notify.info({
                        title: 'INFORMAÇÃO DO CARTÃO',
                        message: 'O Nome do titular do cartão, não pode conter caracteres especiais',
                        duration: 0
                    });
                }

                if(error == 'request.card.number'){
                    this.$Notify.info({
                        title: 'INFORMAÇÃO DO CARTÃO',
                        message: 'O numero informado do cartão nao é valido. Verifique e tente novamente.',
                        duration: 0
                    });
                }

                if(error == 'request.card.exp_month'){
                    this.$Notify.info({
                        title: 'INFORMAÇÃO DO CARTÃO',
                        message: 'Data de validade do cartão é invalida. Verifique e tente novamente.',
                        duration: 0
                    });
                }

                if(error == 'request.card.cvv'){
                    this.$Notify.info({
                        title: 'INFORMAÇÃO DO CARTÃO',
                        message: 'CVV/CVC inválido. É preciso ter no minimo 3 digitos e no maximo 4. Verifique e tente novamente.',
                        duration: 0
                    });
                }

                return false;
            }

        },
        async finalizePaymentPagarme(){
            if(!this.verifyAddressUser()) {
                this.enabledFullScreenLoading({ status: false});

                this.$Notify.info({
                    message: this.translateWord('Para finalizar o pagamento, é preciso preencher o endereço'),
                    duration: 0
                });

                return;
            }

            this.$Notify.info({
                title: this.translateWord('Analisando cartão'),
                message: this.translateWord('Estamos enviando os dados do seu cartão para analise, aguarde...')
            });

            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_authorized'){
                    if(this.product.isTrialDays || this.isCoupon100Percent){
                        await this.createFuturePaymentAfterPaymentSuccess();
                        return this.paymentSuccess();
                    }

                    return this.paymentSuccess();
                }

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_done') return this.paymentSuccess();

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({ status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ocorreu algum na requisição, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async finalizePaymentPagarmeBoleto(){
            if(!this.verifyAddressUser()) {
                this.enabledFullScreenLoading({ status: false});

                this.$Notify.info({
                    message: this.translateWord('Para finalizar o pagamento, é preciso preencher o endereço'),
                    duration: 0
                });

                return;
            }

            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_pending'){
                    this.setBoletoDetails({
                        barCode: this.responseBuilderallGateway.gateway.boleto.barcode_number,
                        barCodeImage: this.responseBuilderallGateway.gateway.boleto.barcode_image,
                        downloadPdf: this.responseBuilderallGateway.gateway.boleto.url_download,
                        qrCode: this.responseBuilderallGateway.gateway.boleto.qrcode
                    });

                    this.paymentSuccess();

                    return;
                }

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({ status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ocorreu algum na requisição, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async finalizePaymentPagarmePix(){
            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_pending'){
                    
                    this.setBgResponse({ payment: this.responseBuilderallGateway });

                    this.setPixDetails({
                        qrCode: this.responseBuilderallGateway.gateway.pix.qr_code,
                        qrCodeUrl: this.responseBuilderallGateway.gateway.pix.qr_code_url
                    });

                    this.paymentSuccess();

                    return;
                }

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({ status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ocorreu algum na requisição, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async finalizePaymentAdyen(){
            if(this.adyen.isThreeSecureUrl && this.adyen.threeSecureIdentify && this.adyen.isPaymentThreeSecure){
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), {
                    application: 'OFFICE',
                    is_3d_secure: true,
                    identify_cache: this.adyen.threeSecureIdentify,
                    id_user: this.user.cryptId,
                    amount: {
                        currency: this.product.currency
                    }
                })).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_done') return this.paymentSuccess();

                return this.paymentRefused();
            }

            if(!this.verifyDataCardIsValid()) return this.enabledFullScreenLoading({ status: false});

            if(!this.verifyAddressUser()) {
                this.enabledFullScreenLoading({ status: false});

                this.$Notify.info({
                    message: this.translateWord('Para finalizar o pagamento, é preciso preencher o endereço'),
                    duration: 0
                });

                return;
            }
            
            this.$Notify.info({
                title: this.translateWord('Analisando cartão'),
                message: this.translateWord('Estamos enviando os dados do seu cartão para analise, aguarde...')
            });

            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_redirect_user'){
                    let positionHash = window.location.href.search('#');
                    let urlAfterPayment = `${positionHash !== -1 ? window.location.href.slice(0,positionHash) : window.location.href}${window.location.search == '' ? '?' : '&'}checkoutStep=Payment&threeSecure=1&invoice=${this.fatura.invoice.id}`;

                    return this.paymentRedirectUser3Ds('adyen', {
                        issuerUrl: this.responseBuilderallGateway.gateway.others.issuerUrl,
                        md: this.responseBuilderallGateway.gateway.others.md,
                        paRequest: this.responseBuilderallGateway.gateway.others.paRequest,
                        termUrl: `${Helper.getEnvs().urlCheckout}/adyen/3d-secure?identify=${this.user.name}_${(new Date()).getTime()}&returnUrl=${encodeURIComponent(urlAfterPayment)}`
                    });
                }

                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_authorized'){
                    if(this.product.isTrialDays || this.isCoupon100Percent){
                        await this.createFuturePaymentAfterPaymentSuccess();
                        return this.paymentSuccess();
                    }

                    return this.paymentSuccess();
                }
                
                if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_done') return this.paymentSuccess();

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({ status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ocorreu algum na requisição, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async finalizePaymentAdyenSepa(){
            if(!this.verifyDataSepaIsValid()) return this.enabledFullScreenLoading({ status: false});

            if(!this.verifyAddressUser()) {
                this.enabledFullScreenLoading({ status: false});

                this.$Notify.info({
                    message: this.translateWord('Para finalizar o pagamento, é preciso preencher o endereço'),
                    duration: 0
                });
                
                return;
            }

            this.$Notify.info({
                title: this.translateWord('Verificando conta'),
                message: this.translateWord('Estamos verificando sua conta  IBAN!')
            });

            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.status == 'Success') return this.paymentSuccess();

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({ status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ocorreu algum na requisição, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async finalizePaymentPaypal(){
            localStorage.setItem('canSkipStep', true);
            localStorage.setItem('redirectUrlAfterPayment', this.product.redirect);
            
            let positionHash = window.location.href.search('#');
            let urlAfterPayment = `${positionHash !== -1 ? window.location.href.slice(0,positionHash) : window.location.href}${window.location.search == '' ? '?' : '&'}checkoutStep=PaymentConfirmation`
            let countryPrice = '';

            if(this.isUserFatherToReceiveByGateway) await this.checkUpdateInvoiceFirstPayment();

            if(this.isChargedAmount){
                countryPrice += `&cp=1&cp_planId=${this.product.id}&cp_currency=${this.product.currency}&cp_amount=${this.product.amount}&cp_symbol=${this.product.currencySymbol}`;
            }

            if(this.isPaymentCountryPrice && !this.isChargedAmount){
                countryPrice += `&cp=1&cp_planId=${this.product.id}&cp_currency=${this.planCountryPrice.currency}&cp_amount=${this.planCountryPrice.amount}&cp_symbol=${this.planCountryPrice.symbol}&cp_currencyRef=${this.planCountryPrice.currencyRef}&cp_amountRef=${this.planCountryPrice.amountRef}&cp_fakeAmount=${this.planCountryPrice.fakeAmount}`;

                if(this.planCountryPrice.amountFirstMonth > 0){
                    countryPrice += `&cp_amountFirstMonth=${this.planCountryPrice.amountFirstMonth}&cp_amountFirstMonthRef=${this.planCountryPrice.amountFirstMonthRef}`;
                }
            }

            //Adicionando cupom na sessao para integração com a impact
            //Ao realizar a integração com o novo paypal, pode remover. Pois não vai ser necessario sair do checkout para finalizar o pagamento com o paypal
            if(this.hasCouponAndIsValid){
                sessionStorage.setItem('coupon_impact_paypal', true);
                sessionStorage.setItem('coupon_impact_orderPromoCode', this.coupon.code);
                sessionStorage.setItem('coupon_impact_orderDiscount', this.product.amountCouponDiscount);
            }

            // "/0/1" é para forçar o redirecionamento para o PayPal
            let url = `${Helper.getEnvs().urlOffice}/${this.user.lang}/${this.endpointOffice.INVOICE_PAY}/${this.fatura.invoice.id}/0/1?allow_internal_payment=1&${this.$route.query['user-id'] != undefined ? `user-id=${this.$route.query['user-id']}&invoice=${this.fatura.invoice.id}&` : ''}force_redir_url=${encodeURIComponent(urlAfterPayment)}${countryPrice}`;

            window.location.href = url;
        },
        async finalizePaymentPagseguro(){
            let formData = new FormData();
            formData.append('id', this.fatura.invoice.id);
            formData.append('id_usuario', this.user.user_identifier);
            formData.append('id_franquia', 30877);

            if(this.isUserFatherToReceiveByGateway) await this.checkUpdateInvoiceFirstPayment();
            
            let codePagSeguro = (await this.$httpOffice.post(`${this.endpointOffice.PAGSEGURO}${!this.isPlan ? '/SINGLE' : ''}`, formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )).data['code'];

            /*if(Helper.isMobile()){
                return window.location.href = Helper.getEnvs().env == 'prod' 
                    ? `https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${codePagSeguro}`
                    : `https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code=${codePagSeguro}`;
            }*/

            PagSeguroLightbox({ code: codePagSeguro }, {
                success:  (result) => {
                    this.enabledFullScreenLoading({ status: false});
                    this.paymentSuccess();
                },
                abort: (abort) => {
                    this.enabledFullScreenLoading({ status: false});
                    console.log('abort:', abort);
                }
            });
        },
        verifyCountryPricePayPaypal(){
            if(this.isPaymentCountryPrice){
                let planStyle = TempRedirPlan.verifyAccessUserPlan(this.getSlug, this.product.currency);
                localStorage.setItem('canSkipStep', true);

                if(planStyle == 'COUNTRY_PRICE') window.location.href = `${TempRedirPlan.getUrlRedir()}?checkoutStep=Payment&paymentMethod=paypal`;

                return true;
            }
        },
        verifyDataSepaIsValid(){
            if(this.adyen.sepa.ownerName == ''){
                this.$Notify.warning({
                    title: this.translateWord('Owner Name'),
                    message: this.translateWord('O Campo Owner Name não pode ficar vazio'),
                    duration: 0
                });
                return false;
            }

            if(this.adyen.sepa.ibanNumber == ''){
                this.$Notify.warning({
                    title: this.translateWord('IBAN Number'),
                    message: this.translateWord('O Campo IBAN Number não pode ficar vazio'),
                    duration: 0
                });
                return false;
            }

            return true;
        },
        verifyDataCardIsValid(){
            let publicKey = ['prod'].includes(Helper.getEnvs().env)
                ? this.adyenKeys.publicKeyLive
                : this.adyenKeys.publicKeyTest;

            let cseInstance = adyen.encrypt.createEncryption(publicKey, {
                enableValidations: false
            });

            this.adyen.cardAdyenEncrypt = cseInstance.encrypt(this.adyen.card);
            let card = cseInstance.validate(this.adyen.card);
            
            if(!card.valid){
                if(!card.number){
                    this.$Notify.warning({
                        title: this.translateWord('Numéro inválido'),
                        message: this.translateWord('O Numéro do cartão é inválido'),
                        duration: 0
                    });
                }

                if(!card.holderName){
                    this.$Notify.warning({
                        title: this.translateWord('Titular inválido'),
                        message: this.translateWord('O Titular do cartão esta incorreto'),
                        duration: 0
                    });
                }

                if(!card.expiryYear){
                    this.$Notify.warning({
                        title: this.translateWord('Ano inválido'),
                        message: this.translateWord('O Ano de expiração do cartão é inválido'),
                        duration: 0
                    });
                }

                if(!card.expiryMonth){
                    this.$Notify.warning({
                        title: this.translateWord('Mês invalido'),
                        message: this.translateWord('O mês de expiração do cartão é inválido'),
                        duration: 0
                    });
                }

                if(!card.cvc){
                    this.$Notify.warning({
                        title: this.translateWord('CVC invalido'),
                        message: this.translateWord('O CVC é inválido'),
                        duration: 0
                    });
                }

                return false;
            }

            return true;
        },
        verifyAddressUser(){
            if(this.product.currency == 'BRL'){
                return Object.values({
                    zipCode: this.address.zipCode,
                    city: this.address.city,
                    street: this.address.street,
                    stateCode: this.address.stateCode
                }).every(data => data != '');
            }

            return Object.values({
                zipCode: this.address.zipCode,
                city: this.address.city,
                street: this.address.street,
                country: this.address.country
            }).every(data => data != '');
        },
        verifyAditionalInfo(){
            let cpfOrCnpj = this.pagarme.card.cpfOrCnpj;

            let isCpf = CpfVerifier.isValid(cpfOrCnpj);
            let isCnpf = CnpjVerifier.isValid(cpfOrCnpj);

            if(isCpf || isCnpf) return true;

            this.$Notify.info({
                title: this.translateWord('CPF/CNPJ Invalido'),
                message: this.translateWord("O CPF/CNPJ informado não esta correto. Verifique e tente novamente."),
                duration: 0
            });

            return false;
        },
        async finalizePaymentStripe(){            
            if(this.stripe.holderName == ''){
                this.enabledFullScreenLoading({ status: false});

                this.stripe.fieldsValidators.holderNameIsValid = false;
                
                return this.$Notify.info({
                    title: this.translateWord('Titular inválido'),
                    message: this.translateWord('O Titular do cartão esta incorreto'),
                    duration: 0
                });
            }

            this.$Notify.info({
                title: this.translateWord('Analisando cartão'),
                message: this.translateWord('Estamos enviando os dados do seu cartão para analise, aguarde...')
            });

            try{
                this.stripe.sourceToken = await this.stripe.instance.createToken(this.stripe.card).then(res => res);

                if(this.stripe.sourceToken.error){
                    this.enabledFullScreenLoading({status: false});

                    this.$logs.post('', [`Falha na criação do cartao com Stripe: ${this.user.name} | ${this.user.user_identifier}`, {
                        message: this.stripe.cardSetup.error,
                        product: this.product.title
                    }, 'ERROR']);

                    return this.$Notify.info({
                        title: this.translateWord('Erro na autenticação do cartão'),
                        message: this.translateWord('Ocorreu o seguinte error no analise do cartão') + `: ${this.stripe.sourceToken.error.message}`,
                        duration: 0
                    });
                }

                this.stripe.sourceToken = this.stripe.sourceToken.token.id;

                this.stripe.cardSetup = await this.stripe.instance.handleCardSetup(this.stripe.setupIntent.client_secret, this.stripe.card, {
                    payment_method_data: {
                        billing_details: {
                            name: this.stripe.holderName
                        }
                    }
                });

                if(this.stripe.cardSetup.error){
                    this.enabledFullScreenLoading({status: false});

                    this.$logs.post('', [`Falha na criação do cartao com Stripe: ${this.user.name} | ${this.user.user_identifier}`, {
                        message: this.stripe.cardSetup.error,
                        product: this.product.title
                    }, 'ERROR']);

                    return this.$Notify.info({
                        title: this.translateWord('Erro na autenticação do cartão'),
                        message: this.translateWord('Ocorreu o seguinte error no analise do cartão') + `: ${this.stripe.cardSetup.error.message}`,
                        duration: 0
                    });
                }
            }catch(error){
                this.enabledFullScreenLoading({status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ops infelizmente ocorreu um error ao realizar a requisição de pagamento, favor tente mais tarde'),
                    duration: 0
                });
            }


            try{
                this.responseBuilderallGateway = (await this.$http.post(this.getEndpoint(), this.createDataForSend())).data;

                if(this.responseBuilderallGateway.gateway.message == 'payment_authentication_required'){
                    let isAuthorized = await this.scaAuthenticationRequiredStripe();

                    if(isAuthorized) return this.paymentSuccess();
                }else{
                    if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_authorized'){
                        if(this.product.isTrialDays || this.isCoupon100Percent){
                            await this.createFuturePaymentAfterPaymentSuccess();
                            return this.paymentSuccess();
                        }

                        return this.paymentSuccess();
                    }
                    
                    if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_done'){

                        this.setBgResponse({ payment: this.responseBuilderallGateway });

                        return this.paymentSuccess();
                    }
                }

                return this.paymentRefused();

            }catch(error){
                this.enabledFullScreenLoading({status: false});

                return this.$Notify.warning({
                    title: this.translateWord('Erro requisição'),
                    message: this.translateWord('Ops infelizmente ocorreu um error ao realizar a requisição de pagamento, favor tente mais tarde'),
                    duration: 0
                });
            };
        },
        async scaAuthenticationRequiredStripe(newResponseBuilderallGateway){
            let response = newResponseBuilderallGateway || this.responseBuilderallGateway;

            let client_secret = response.gateway.others.error.payment_intent.client_secret;
            let payment_method = response.gateway.others.error.payment_method.id;

            let resultIntent = await this.stripe.instance.handleCardPayment(client_secret, {
                payment_method: payment_method
            });

            return resultIntent.paymentIntent.status == 'succeeded' ? true : false;
        },
        async finalizePaymentMyCard(){
            this.responseBuilderallGateway = (await this.$http.post(this.endpointGateway.debitPaymentTokenCard, this.createDataPaymentMyCard())).data;

            if(this.responseBuilderallGateway.gateway.status == 'Success' && this.responseBuilderallGateway.gateway.message == 'payment_done'){
                this.setBgResponse({ payment: this.responseBuilderallGateway });

                return this.paymentSuccess();
            }

            return this.paymentRefused();
        },
        /**
         * Pega o endpoint se é para pagamento com stripe ou adyen)
         *
         * @returns {string}
         */
        getEndpoint(){
            if(this.stripe.isVisible) return this.endpointGateway.paymentStripe;
            if(this.adyen.isVisible && this.paymentMethodSelected == 'creditCard') return this.endpointGateway.paymentAdyen;
            if(this.adyen.isVisible && this.paymentMethodSelected == 'sepa') return this.endpointGateway.sepa;
            if(this.pagarme.isVisible && this.paymentMethodSelected == 'creditCard') return this.endpointGateway.paymentPagarme;
            if(this.pagarme.isVisible && this.paymentMethodSelected == 'boleto') return this.endpointGateway.paymentPagarmeBoleto;
            if(this.pagarme.isVisible && this.paymentMethodSelected == 'pix') return this.endpointGateway.paymentPagarmePix;
        },
        async searchLocaleZipCode(){
            if(this.adyen.isVisible) await this.searchZipCodeEurope();
            if(this.pagarme.isVisible) await this.searchZipCodeBrazil();
        },
        async searchZipCodeEurope(){
            if(this.address.zipCode == '' || this.address.country != '' || this.address.city != '') return;

            this.enabledFullScreenLoading({ status: true, text: this.translateWord('Analisando localização pelo CEP') });

            try{
                let result = (await axios.get(`https://api.zippopotam.us/${this.localeUser.country}/${this.address.zipCode}`)).data;

                this.address.city = result.places[0]['place name'];
                this.address.country = result.country;

                this.enabledFullScreenLoading({status: false});
            }catch(error){
                this.$Notify.warning({
                    message: this.translateWord('Infelizmente não conseguimos recuperar a localização pelo CEP. Favor preencha manualmente')
                });

                this.enabledFullScreenLoading({status: false});
            }
        },
        async searchZipCodeBrazil(){
            if(this.address.zipCode == '' || this.address.city != '') return;

            this.enabledFullScreenLoading({ status: true, text: this.translateWord('Analisando localização pelo CEP') });

            try{
                let result = (await axios.get(`https://viacep.com.br/ws/${this.address.zipCode}/json/`)).data;

                this.address.stateCode = result.uf;
                this.address.city = result.localidade;
                this.address.street = result.logradouro;
                this.address.district = result.bairro;
                this.address.country = 'BR';

                this.enabledFullScreenLoading({status: false});
            }catch(error){
                this.$Notify.warning({
                    message: this.translateWord('Infelizmente não conseguimos recuperar a localização pelo CEP. Favor preencha manualmente')
                });

                //Caso preencha manualmente, setar o BR.
                this.address.country = 'BR';

                this.enabledFullScreenLoading({status: false});
            }
        },
        verifyActiveContinueStep(){
            if(!this.isCheckCouponFixedValue) return this.disabledBtnContinue = true;
            
            if(this.product.currency == 'BRL' || this.isFirstPaymentUpLine){
                this.disabledBtnContinue = false;    
            }else if(this.product.currency == 'EUR' && this.paymentMethodSelected == 'creditCard'){
                this.checkFieldsCreditCard();
                this.checkFieldsAddress();

                let cardFieldsValidate = Object.entries(this.adyen.fieldsValidators).map(res => res[1].test).every(test => test == true);
                let addressFieldsValidate = Object.entries(this.address.fieldsValidators).map(res => res[1].test).every(test => test == true);

                this.disabledBtnContinue = cardFieldsValidate && addressFieldsValidate ? false : true;
            }else if(['USD', 'CZK', 'THB'].includes(this.product.currency) && this.paymentMethodSelected == 'creditCard'){
                this.checkFieldsCreditCard();

                let cardFieldsValidate = Object.entries(this.adyen.fieldsValidators).map(res => res[1].test).every(test => test == true);

                this.disabledBtnContinue = cardFieldsValidate ? false : true;
            }else if(this.product.currency == 'EUR' && this.paymentMethodSelected == 'sepa'){
                this.checkFieldsSepa();
                this.checkFieldsAddress();

                let sepaFieldsValidate = Object.entries(this.adyen.sepa.fieldsValidators).map(res => res[1].test).every(test => test == true);
                let addressFieldsValidate = Object.entries(this.address.fieldsValidators).map(res => res[1].test).every(test => test == true);

                this.disabledBtnContinue = sepaFieldsValidate && addressFieldsValidate ? false : true;
            }else{
                this.checkFieldsStripeHolderName();
                
                this.disabledBtnContinue = this.stripe.fieldsValidators.holderName.test ? false : true;
            }
        },
        checkFieldsStripeHolderName(){
            this.stripe.fieldsValidators.holderName.test = this.stripe.holderName != '' ? true : false;
        },
        checkFieldsCreditCard(){
            this.adyen.fieldsValidators.holderName.test = this.adyen.card.holderName != '' ? true : false;
            this.adyen.fieldsValidators.number.test = this.adyen.card.number != '' ? true : false;
            this.adyen.fieldsValidators.validate.test = this.adyen.validate != '' ? true : false;
            this.adyen.fieldsValidators.cvc.test = this.adyen.card.cvc != '' ? true : false;
        },
        checkFieldsSepa(){
            this.adyen.sepa.fieldsValidators.ownerName.test = this.adyen.sepa.ownerName != '' ? true : false;
            this.adyen.sepa.fieldsValidators.ibanNumber.test = this.adyen.sepa.ibanNumber != '' ? true : false;
        },
        checkFieldsAddress(){
            this.address.fieldsValidators.zipCode.test = this.address.zipCode != '' ? true : false;
            this.address.fieldsValidators.country.test = this.address.country != '' ? true : false;
            this.address.fieldsValidators.city.test = this.address.city != '' ? true : false;
            this.address.fieldsValidators.street.test = this.address.street != '' ? true : false;
        },
        async checkUpdateInvoiceFirstPayment(){
            if(!this.isFirstPaymentUpLine) return;

            //UPline receber via Paypal
            if(this.isFirstPaymentUpLine && this.userFather.receivement_gateways.hasOwnProperty('paypal') && this.paymentMethodSelected == 'paypal'){
                this.addDataNote({
                    recorrente: 0,
                    valor_desc_adic: this.product.amountFirstMonth,
                    nota: {
                        upline_first_payment: {
                            gateway: 'paypal',
                            identifier: this.userFather.receivement_gateways.paypal.identifier
                        }
                    }
                });
            }

            //UPline receber via Pagseguro
            if(this.isFirstPaymentUpLine && this.userFather.receivement_gateways.hasOwnProperty('pagseguro') && this.paymentMethodSelected == 'pagSeguro'){
                this.addDataNote({
                    recorrente: 0,
                    valor_desc_adic: this.product.amountFirstMonth,
                    nota: {
                        upline_first_payment: {
                            gateway: 'pagseguro',
                            identifier: this.userFather.receivement_gateways.pagseguro.identifier,
                            token: this.userFather.receivement_gateways.pagseguro.token
                        }
                    }
                });
            }
            
            await this.updateNoteInvoice();
        },
        addNoteVatFee(){
            if(this.product.currency == 'EUR'){
                let dataVatDetails = {
                    number_completed: this.vatFee.vatNumberCompleted,
                    number: this.vatFee.vatNumber,
                    country: this.vatFee.vatCountry,
                    fee: this.vatFee.percentage
                };
                
                //Remove todos os valor nulls
                this.addDataNote({
                    nota: {
                        vat_details: Object.fromEntries(Object.entries(dataVatDetails).filter(([key, value]) => value))
                    }
                });
            }
        },
        nextStepWithEmit(){
            this.$emit('step-emit', 'paymentConfirmation');
            this.nextStep();
        },
        prevStepWithEmit(){
            this.$emit('step-emit', 'planOrFee');
            this.prevStep();
        }
    },
    components: {
        Box
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    #stripe-element{
        background: #efefef;
        padding: 15px;
        border-radius: 5px;
    }

    .payment{
        margin-top: 25px;
    }

    .paymentSecure_title{
        font-size: 0.9em;
        color: var(--gray);
        font-weight: 600;
    }

    .paymentSecure{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .paymentSecure_stamps{
        display: flex;
        font-size: 0.7em;
    }

    .paymentSecure_stamps_item{
        display: flex;
        margin-right: 20px;
    }
    .paymentSecure_stamps_item_icon{
        font-size: 1.3em;
        color: var(--blue);
    }

    .payment_paymentMethods_item_content_title{
        margin-bottom: 25px;

        & p:nth-of-type(1){
            font-size: 0.9em;
            font-weight: 600;
        }

        & p:nth-of-type(2){
            font-size: 0.7em;
            font-weight: 300;
        }
    }

    .payment_paymentMethods_item{
        display: flex;
        flex-direction: column;
        border: 2px solid #E9E9E9;
        border-radius: 4px;
        margin-bottom: 15px;
        padding: 20px;

        &:last-child{
            margin-bottom: 0px;
        }
    }
    .payment_paymentMethods_item_title{
        font-size: 0.8em;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .payment_paymentMethods_item_menu{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .payment_paymentMethods_item_menu_data{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .payment_paymentMethods_item_menu_data_symbol{
        position: relative;
        width: 12px;
        height: 12px;
        border: 1px solid #8A8B8D;
        border-radius: 50%;
        margin-right: 10px;
    }
    .payment_paymentMethods_item_menu_data_img{
        width: 20px;
        margin-right: 8px;
    }
    .payment_paymentMethods_item_menu_data_name{
        font-size: 0.9em;
        font-weight: 600;
    }
    .payment_paymentMethods_item_menu_info{
        width: 265px;
        font-size: 0.6em;
        font-weight: 300;
        margin-left: 12px;
        padding-left: 12px;
        border-left: 1px solid var(--gray);
    }

    .payment_paymentMethods_item_content{
        margin-top: 20px;

        & .at-input__original{
            font-size: 12px;
            padding: 8px !important;

            &::placeholder{
                font-size: 12px;
            }
        }
    }

    .paymentMethodsActive{
        & .payment_paymentMethods_item_menu_data_symbol{
            border: none;
        }

        & .payment_paymentMethods_item_menu_data_symbol_ball{
            display: block;
            width: 10px;
            height: 10px;
            background: var(--blue);
            border-radius: 50px;
        }
    }

    .payment_paymentMethods_itemCreditCard{
        & .payment_paymentMethods_item_content{
            display: flex;
            flex-direction: column;
        }
    }
    .payment_paymentMethods_itemCreditCard_brands{
        display: flex;
        margin: auto;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
        border: 1px solid #DCDCDC;
        padding: 10px 20px;
        border-radius: 5px;
    }
    .payment_paymentMethods_itemCreditCard_brands_image{
        width: 40px;
        margin-right: 20px;
    }

    .payment_paymentMethods_itemPaypal,
    .payment_paymentMethods_itemPagseguro,
    .payment_paymentMethods_itemPix{
        & .payment_paymentMethods_item_menu_data img{
            width: 16px;
        }

        & .payment_paymentMethods_item_content{
            font-size: 0.9em;
            text-align: center;
        }
    }

    .payment_paymentMethods_itemMyCards{

        & .payment_paymentMethods_item_content{
            margin-top: 45px;
            margin-left: 23px;
        }

        & .payment_paymentMethods_item_menu_data_img{
            width: 25px;
        }
    }

    .payment_paymentMethods_item_myCards{
        position: relative;
        display: inline-block;
        cursor: pointer;
        border: 2px solid #e9e9e9;
        padding: 5px 10px;
        border-radius: 2px;
        margin-bottom: 30px;
        opacity: 0.7;
        transition: all 0.3s;

        &:last-child{
            margin-bottom: 0px;
        }
    }
    .payment_paymentMethods_item_myCards_check{
        width: 38px;
        height: 38px;
        background: #d7d7d7;
        margin-right: 17px;
        border-radius: 3px;
        transition: all 0.3s;
    }
    .payment_paymentMethods_item_myCards_separete{
        display: flex;
        align-items: center;
    }
    .payment_paymentMethods_item_myCards_brand{
        width: 40px;
    }
    .payment_paymentMethods_item_myCards_number{
        margin-left: 20px;
        letter-spacing: 3px;
        font-weight: bold;
    }
    .payment_paymentMethods_item_myCards_validate{
        position: absolute;
        top: -17px;
        right: 1px;
        font-size: 0.6em;
        font-weight: bold;
    }

    .payment_paymentMethods_item_myCardsActive{
        border-color: #1d9960;
        opacity: 1;

        & .payment_paymentMethods_item_myCards_check{
            border-radius: 50%;
            background: #1d9960;
        }
    }
    .payment_actions{
        margin-top: 25px;
    }
    
    .payment_actions_devModePaymentConfirmation{
        margin-top: 10px !important;
        font-size: 0.7em !important;
        background: #718518 !important;
    }

    @include media(sm,md,lg){
        .payment_paymentMethods_item_menu{
            display: block;
        }

        .payment_actions{
            position: fixed;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin-bottom: 10px;
        }
    }
</style>