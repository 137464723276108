<template>
    <div id="app" :class="{'rtl' : rtlActive}">
        <FullScreenLoading v-if="fullScreenLoading.status" :text="fullScreenLoading.text"/>
        
        <div class="app_loading">
            <Loading v-show="!isPaginaCarregada && !errorLoading" :title="title"></Loading>
        </div>

        <div class="app_errorLoading" v-if="errorLoading && !isTeamAccount">
            <h1 class="mb30">{{ translateWord('Opsss, Infelizmente ocorreu algum erro no carregamento do checkout, tente novamente clicando no botão abaixo') }}</h1>
            <a class="btn btn_azul" href="">{{ translateWord('Recarregar checkout') }}</a>
        </div>

        <div class="app_teamAccount" v-if="errorLoading && isTeamAccount">
            <span class="app_teamAccount_img icon icon-users"></span>
            <h1 class="mb30">{{ translateWord('Não é possivel efetuar compra com conta time!') }}</h1>
        </div>
        
        <router-view v-if="isPaginaCarregada && !errorLoading"></router-view>
        
        <SupportChat v-if="enabledSupport" :is-brl="isSupportBrl"/>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import cookie from 'js-cookie';

import Loading from './components/Loading';
import FullScreenLoading from './components/FullScreenLoading';
import SupportChat from './components/SupportChat';

import Helper from './assets/js/helper';
import PixelAnalytics from './assets/js/PixelAnalytics';
import TempRedirPlan from './assets/js/TempRedirPlan';

export default{
    data(){
        return {
            subdomain: null,
            aid: null,
            isPaginaCarregada: false,
            errorLoading: false,
            isTeamAccount: false,
            title: '',
			rtlActive: false,
            enabledSupport: false,
            isSupportBrl: false,
            lang: null
        }
    },
    computed: {
        ...mapState([
            'endpointOffice',
            'endpointGateway',
            'user',
            'userFather',
            'plano',
            'taxa',
            'coupon',
            'isPlan',
            'isBuyTool',
            'isPaymentOnlyPaypal',
            'isPaymentDomain',
            'isPaymentDomainRegistry',
            'isPaymentDomainExtraConnection',
            'isPaymentStorage',
            'paymentMethodEnabled',
            'fullScreenLoading',
            'localeUser',
            'paymentMethodSelected', //Remover nao esta sendo usado
            'fatura',
            'fakeAmountPlanOrFee',
            'isPaymentPlanWordpressCloud',
            'isPaymentCheetahTemplate',
            'cheetahTemplateDetails',
            'isPaymentCountryPrice',
            'planCountryPrice',
            'isPauseAccount',
            'devMode'
        ]),
        ...mapGetters([
            'getSlug',
            'translateWord',
            'product',
            'productAmount',
            'getAllParamsUrl',
            'isPlanToActive',
            'hasCouponAndIsValid',
            'urlOfficeUser',
            'impact/isUserImpact',
            'hasBundlePlan'
        ])
    },
    async created(){
        if(window.location.pathname.search('payment') < 1){
            this.isPaginaCarregada = true;
            return;
        }

        try{
            //Limpa os dados de nota no localStorage
            this.clearDataNote();
            
            if(this.$route.query['dev-mode'] == '1'){
                this.setDevMode(true);
            }

            //Setar PGM
            Helper.appendPgm();

            /**
             * Identifica se o usuario esta logado ou não
             */
            await this.verifyUserIsLogged();

            //No caso do this.userFather.id, so vai pegar quando o user estiver logado
            this.aid = cookie.get('IdPaiOffice') || Helper.getAidOffice() || this.$route.query.aid || this.userFather.id || Helper.builderallAccounts.internetMarketingLLC_10;

            if(this['impact/isUserImpact']){
                this.aid = Helper.builderallAccounts.impact_2494737;
            }

            if(!this.user.isLogged && this.aid || this['impact/isUserImpact']){
                try {
                    let userFather = (await this.$httpOffice.get(`${this.endpointOffice.ACCOUNT_INFO}?id=${this.aid}`)).data;

                    this.setDataUserFather({
                        id: userFather.id,
                        name: userFather.name,
                        avatar: userFather.avatar
                    })
                }catch(e){

                }

            }
            
            /**
             * Pega chaves de tradução
             */
            await this.verifyTranslation();

            await this.paymentPlanCheetahTemplate();

            if(this.user.isLogged){
                //TODO mover para onde fica o paymentPlanCheetahTemplate. usuario nao precisa esta logado para validar
                await this.paymentPlanWordpressCloud();

                this['impact/identify']();

                await this.createTokenCheckout();
            }

            /**
             * Busca o plano ou Taxa
             */
            await this.verifyPlanOrFeeOrToolAccess();

            /**
             * Verifica se a conta é time
             */
            await this.verifyTeamAccount();

            /**
             * Verifica cupom de desconto
             */
            await this.verifyCoupon();

            /**
             * Se pular para proximo step, finaliza o carregamento principal do checkout
             */
            if(localStorage.getItem('canSkipStep') && this.$route.query['checkoutStep'] !== undefined && this.$route.query['checkoutStep'] == 'PaymentConfirmation'){
                this.isPaginaCarregada = true;
                return;
            }

            /**
             * Realiza o pixel da builderall para identificação do usuario pai
             */
            if(this.aid != null) {
                Helper.setBuilderallPixel(this.aid, this.user.lang, this.subdomain);
            }
            
            /**
             * Algumas das configurações do usuario, como a inserção na coluna nota.
             * so pode ser feita apos a criação da invoice. por isso é necessario que o usuario esteja logado antes
             * para criação da invoice e inserção dos dados na coluna nota
             */
            if(this.user.isLogged){
                await this.getOrCreateInvoice();
                await this.createInvoicePlanToActive();


                this.addDataNote({nota: {pgm: this.$route.query['pgm'] || sessionStorage.getItem('pgm')}});
    
                this.updateNoteInvoice();
                
                if(this.isPaymentPlanWordpressCloud){
                    this.addDataNote({ nota: { wp_order_id: this.$route.query['stn_wp_order_id'], wp_amount: this.product.amount}});
                    await this.updateNoteInvoice();
                }

                if(this.isPaymentCheetahTemplate){
                    this.addDataNote(
                        { 
                            nota: { 
                                templates_slugs: this.cheetahTemplateDetails.title
                            },
                            valor_desc_adic: this.cheetahTemplateDetails.amount
                        }
                    );

                    await this.updateNoteInvoice();
                }

                if(this.isPaymentDomainRegistry){
                    await this.updateNoteInvoice();
                }
            }

            await this.getLocaleUserAccess();

            if(this.product.currency == 'BRL'){
                let script  = document.createElement('script');
                script.type = 'text/javascript';
                ['dev', 'test'].includes(Helper.getEnvs().env)
                    ? script.src  = 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js'
                    : script.src = 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js';

                document.head.appendChild(script);
            }

            /**
             * Bloquear pagamentos em BRL para fora do brasil
             */
            this.blockPaymentOutsideCurrencyBrazil();

            /**
             * Ativar Chat para Suporte
             */
            this.verifySupportEnabled();

            /**
             * Exibir metodos de pagamentos disponiveis para determinada moeda
             */
            this.showSelectPaymentMethod();
            
            /**
             * Verifica se é para exibir metodos de pagamentos para enviar para UPLine
             */
            await this.verifyFirstPaymentUpLinePaymentGateway();

            this.isPaginaCarregada = true;
        }catch(error){
            console.log(error);

            this.$logs.post('', [`Falha no carregamento do checkout com o: ${this.user.name} | ${this.user.user_identifier}`, {
                message: error,
                product: this.product.title != undefined ? this.product.title : 'EMPTY PRODUCT'
            }, 'ERROR']);

            if(error == 'continue_load') return this.isPaginaCarregada = false;

            if(!['plan_or_fee_not_found', 'currency_not_available', 'plan_or_fee_inactive', 'unavailable'].includes(error)){
                this.errorLoading = true;
                return false;
            }
        }
    },
    methods:{
        ...mapMutations([
            'setLocaleUser',
            'addValueVat',
            'hasInvoiceUrlValid',
            'setDataUserFather',
            'setDataUser',
            'setDataPlan',
            'setDataFee',
            'setDataTool',
            'setIsPlan',
            'setCoupon',
            'setFakeAmountPlanOrFee',
            'setIsPaymentOnlyPaypal',
            'setInvoicePlanToActive',
            'selectPaymentMethod',
            'enabledMyCards',
            'enabledCreditCard',
            'enabledSepa',
            'enabledPaypal',
            'enabledPagSeguro',
            'enabledBoleto',
            'enabledPix',
            'isPaymentUpLine',
            'setPaymentPlanWordpressCloud',
            'setPaymentCheetahTemplate',
            'setCheetahTemplateDetails',
            'addDataNote',
            'clearDataNote',
            'setPaymentDomain',
            'setPaymentDomainRegistry',
            'setPaymentStorage',
            'setPaymentDomainExtraConnection',
            'setIsBuyTool',
            'setIsBuyPlanOrFee',
            'setIsPaymentCountryPrice',
            'changePeriodRecurring',
            'setIsChargedAmount',
            'setIsPauseAccount',
            'impact/identify',
            'setDevMode',
            'setMyCreditCard',
            'setTokenCheckout'
        ]),
        ...mapActions([
            'validateCoupon',
            'updateNoteInvoice',
            'verifyCurrentPlanUser',
            'getTranslation',
            'getLocaleUserAccess',
            'getCountryPrices',
            'getConvertCurrency'
        ]),
        async verifyUserIsLogged(){
            let userLogged = (await this.$httpOffice.get(this.$store.state.endpointOffice.IDENTIFY_LOGGED_IN_USER)).data;

            this.setDataUser(userLogged);

            if(!this.user.isLogged) return false;

            await this.verifyCurrentPlanUser();
            
            //Para usuarios antigos, pode vim para pagar com somente paypal
            if(this.user.is_payment_only_paypal) this.setIsPaymentOnlyPaypal(true);
            
            this.setDataUserFather({
                id: this.user.parent.aid,
                name: this.user.parent.name,
                avatar: this.user.parent.avatar,
                receivement_gateways: this.user.parent.receivement_gateways
            });

            //Seta o ID do usuario em localStorage para o CRM
            localStorage.setItem('id_user_logged', userLogged.user_identifier);
        },
        async verifyTeamAccount(){
            if(this.user.is_team_account && !this.isPaymentDomain && !this.isPaymentStorage && !this.isPaymentDomainExtraConnection) {
                this.isTeamAccount = true;
                this.isPaginaCarregada = true;
                this.errorLoading = true;
                throw 'is_team_account';
            }
        },
        async verifyTranslation(){
            this.lang = this.user.isLogged ? this.user.lang : Helper.convertSigla(Helper.getLangBrowser());
            
            //Temporario ate traduzir o polones
            this.lang = this.lang == 'pl' ? 'en' : this.lang;
            
            await this.getTranslation({
                sigla: this.lang,
                category: ''
            });

            //Se o idioma for em hebraico, adiciona o RTL
            if(['il', 'he'].includes(this.lang)) this.rtlActive = true;
            
            if(this.user.isLogged){
                this.title = this.translateWord('Olá {0}, o checkout esta sendo preparado aguarde!', [
                    `<b>${this.user.name}</b>`
                ]);
            }else{
                this.title = this.translateWord('O checkout esta sendo preparado aguarde!');
            }
        },
        async verifyPlanOrFeeOrToolAccess(){
            let testTypeAccess = this.$route.query['typeAccess'] || null;

            let planOrFee = null;
            let tool = {status: 'error'};

            if(testTypeAccess == 'tool'){
                planOrFee = {status: 'error'};
            }else{
                planOrFee = (await this.$httpOffice.get(`${this.$store.state.endpointOffice.PEGAR_PLANO_OU_TAXA}/${this.getSlug}?user_lang=${this.lang}`)).data;
            }

            //Plano ou taxa nao encontrado
            if(planOrFee.status == 'error' && Helper.getEnvs().env != 'prod'){
                //tool = (await this.$httpOffice.get(`${this.$store.state.endpointOffice.PEGAR_PLANO_OU_TAXA}/${this.getSlug}?user_lang=${this.lang}`)).data; //Buscar os dados da tool
                tool = {
                    "status":"success",
                    "type":"tool",
                    "data":{
                        "id":"3025",
                        "id_franquia":"30877",
                        "id_subsidiary":null,
                        "trial_days":"0",
                        "trial_valor":"0.00",
                        "logo": require('./assets/tool.svg'),
                        "titulo":"Worckie",
                        "banner":null,
                        "slug":"funnel-club-eur-89-2021",
                        "descricao":"Aumente a produtividade e gerencie sua equipe com a plataforma mais eficiente para gerenciamento de projetos.",
                        "url_after_payment":"https:\/\/office.builderall.com\/us\/office",
                        "periodo":"MENSAL",
                        "amount": 69.90,
                        "additional_value_first_month":0,
                        "valor_tipo":"ACRESCIMO",
                        "visivel":"1",
                        "ocultar_listagem":"0",
                        "is_free":"0",
                        "moeda":"EUR",
                        "suggestions":[
                            {
                                id: 4253,
                                name: 'MaillingBoss',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 80.99
                            },
                            {
                                id: 4254,
                                name: 'Builderall Builder',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 80.99
                            },
                            {
                                id: 4255,
                                name: 'Builderall Zap',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 29.99
                            },
                            {
                                id: 4256,
                                name: 'ChatBot',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 29.99
                            },
                            {
                                id: 4257,
                                name: 'E-Learning',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 29.99
                            },
                            {
                                id: 4258,
                                name: 'Quiz',
                                description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...',
                                amount: 19.99
                            }
                        ],
                        "is_franchise":"1",
                        "franchise_type":"2TIER",
                        "franchise_steps":"0",
                        "destaque":"0",
                        "excluido":"0",
                        "level":"2",
                        "mega_bonus":"0",
                        "activate_affiliation":"1",
                        "affiliation_type":"PRO",
                        "redir_on_inactive":"",
                        "is_franchise_builderall":"0",
                        "franchise_builderall_no_residue":"0",
                        "theme_type":"PREMIUM",
                        "affiliate_common_commission":"1",
                        "webhooks":"[\"http:\\\/\\\/www.admin.builderall.local\\\/us\\\/notificacao-pagamento-fatura-franquia\\\/builderall_gateway\"]",
                        "is_a_third_party_product":"0",
                        "is_white_label":"0",
                        "email_product_owner":null,
                        "fake_amount":null,
                        "first_payment_to_upline":"0",
                        "upgrade_plans":[
                            "wp-funnel-club-eur"
                        ],
                        "type":"DEFAULT",
                        "options_to_hide":null,
                        "has_unlimited_domains":"0",
                        "mb_tags":"FC",
                        "mb_list":"FUNNEL_CLUB",
                        "payment_gateways":[
                            "2",
                            "7"
                        ],
                        "itens":{
                            "551":{
                                "id_plano":"3025",
                                "id_recurso_item":"551",
                                "qtde":"30.00",
                                "id_recurso":"4",
                                "valor":"1.00",
                                "titulo":"label_leads_unlimited_quota",
                                "total":30
                            },
                            "552":{
                                "id_plano":"3025",
                                "id_recurso_item":"552",
                                "qtde":"15.00",
                                "id_recurso":"3",
                                "valor":"0.50",
                                "titulo":"label_domain_unlimited_subdomain",
                                "total":7.5
                            },
                            "553":{
                                "id_plano":"3025",
                                "id_recurso_item":"553",
                                "qtde":"10.00",
                                "id_recurso":"2",
                                "valor":"0.50",
                                "titulo":"label_storage_per_gb",
                                "total":5
                            },
                            "554":{
                                "id_plano":"3025",
                                "id_recurso_item":"554",
                                "qtde":"1.00",
                                "id_recurso":"1",
                                "valor":"5.00",
                                "titulo":"label_back_office_tools_package",
                                "total":5
                            },
                            "1001":{
                                "id_plano":"3025",
                                "id_recurso_item":"1001",
                                "qtde":"999999.00",
                                "id_recurso":"1000",
                                "valor":"0.00",
                                "titulo":"label_subdomains_per_domain",
                                "total":0
                            }
                        }
                    }
                }
            }


            if(planOrFee.status == 'success'){
                this.setIsBuyPlanOrFee(true);

                await this.executePlanOrFee(planOrFee);
            }

            if(tool.status == 'success'){
                this.setIsBuyTool(true);

                await this.executeTool(tool);
            }

            if(planOrFee.status == 'error' && tool.status == 'error'){
                this.isPaginaCarregada = true;
                this.$router.push({ name: 'NotFoundPlanOrFee' });
                throw 'plan_or_fee_not_found';
            }

            this.$route.query['pixel-event'] ? this.verifyPixelAnalyticsParent() : this.verifyPixelAnalytics();
            
            this.verifyPixelBuilderall();
        },
        async executeTool(tool){
            //Pega a custom thank you page
            tool.data.url_after_payment = await this.verifyCustomThankYouPage(
                this.paramUrlAfterPaymentAjust() == null ? tool.data.url_after_payment : this.paramUrlAfterPaymentAjust()
            );

            this.setDataTool(tool.data);
        },
        async executePlanOrFee(planOrFee){
            this.verifyRedirPlanOrFee(planOrFee);

            await this.verifyRedirIfBuyDoaminAndNotParams();
            this.verifyRedirIfBuyStorageAndNotParams();
            this.verifyRedirIfBuyDomainExtraConnectionAndNotParams();
            this.verifyRedirIfBuyDomainRegistryAndNotParams();

            //Verifica se é plano ou taxa, caso for plano é true, caso for taxa é false.
            this.setIsPlan((planOrFee.type == 'plan' || false));

            //Pega a custom thank you page
            planOrFee.data.url_after_payment = await this.verifyCustomThankYouPage(
                this.paramUrlAfterPaymentAjust() == null ? planOrFee.data.url_after_payment : this.paramUrlAfterPaymentAjust()
            );

            //Ajusta a taxa caso for pagamento de dominio
            if(this.isPaymentDomain) {
                planOrFee = await this.adjustIfPaymentDomain(planOrFee);
            }

            //Ajustar o plano caso for pagamento de storage
            if(this.isPaymentStorage){
                planOrFee = await this.adjustIfPaymentStorage(planOrFee);
            }

            //Ajusta o plano para caso for pagamento de dominio extra
            if(this.isPaymentDomainExtraConnection){
                planOrFee = await this.adjustIfPaymentDomainExtraConnection(planOrFee);
            }

            if(this.isPaymentPlanWordpressCloud){
                planOrFee = await this.adjustBuyWordpressPrice(planOrFee);
            }

            if(this.isPaymentCheetahTemplate){
                planOrFee = await this.adjustBuyCheetahTemplate(planOrFee);
            }

            if(this.isPaymentDomainRegistry){
                planOrFee = await this.adjustIfPaymentDomainRegistry(planOrFee);
            }

            //Verifica pagamento pelo Country Price.
            planOrFee = await this.adjustPaymentCountryPrice(planOrFee);

            //Verifica se o tipo da moeda esta disponivel no checkout
            this.verifyCurrencyAccept(planOrFee);
            
            //Verificar se é para ser pagamento, pause_account
            planOrFee = await this.verifyPauseAccount(planOrFee);

            this.isPlan ? this.setDataPlan(planOrFee.data) : this.setDataFee(planOrFee.data)
        },
        verifyRedirPlanOrFee(PlanOrFee){
            //Taxa de 1 dolar/euro para fazer redir para plano com coupon
            if(this.getSlug == 'promotion-plan-69-usd'){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkout.builderall.io/payment/premium-template-usd-69?coupon=1USD';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/premium-template-usd-69?coupon=1USD';
            }

            if(this.getSlug == 'promotion-plan-59-eur'){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkout.builderall.io/payment/premium-template-eur-59?coupon=1EUR';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/premium-template-eur-59?coupon=1EUR';
            }

            //Regra para usuario: dominanteinove@builderall.com
            if(this.getSlug == 'especial-dominante-inove-97' && this.userFather['id'] != '1676690'){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkout.builderall.io/payment/premium-builderall-brl-149';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/premium-builderall-brl-149';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'https://checkout.builderall.com/payment/premium-builderall-brl-149';
            }

            //Regra para usuario: Richard pedido do alex held
            if(this.getSlug == 'socio-lancador' && this.userFather['id'] != '2230985'){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/essentials-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/essentials-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/essentials-plan';
            }

            //Regra para usuario: Rafa Alatorre
            if(!this.devMode && this.getSlug == 'fabrica-emprendedora' && !['2459523', '2542263'].includes(this.userFather['id'])){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/premium-plus-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/premium-plus-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/premium-plus-plan';
            }

            //Regra para usuario: Rafa Alatorre
            if(!this.devMode && this.getSlug == 'fabrica-emprendedora-trial' && !['2459523', '2542263'].includes(this.userFather['id'])){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/premium-plus-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/premium-plus-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/premium-plus-plan';
            }

            //Regra para usuario: James
            if(this.getSlug == 'james-essentials' && this.userFather['id'] != '125089'){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/essentials-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/essentials-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/essentials-plan';
            }

            //Regra para usuario: Marketing todo en uno LLC
            if(this.getSlug == 'special-premium-wp' && !['1972566', '2193924', '115876'].includes(this.userFather['id'])){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/wp-premium-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/wp-premium-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/wp-premium-plan';
            }

            //Regra para usuario: Marketing todo en uno LLC
            if(this.getSlug == 'special-funnel-club-wp' && !['1972566', '2193924', '115876'].includes(this.userFather['id'])){
                if(Helper.getEnvs().env == 'test') window.location.href = 'https://checkoutfront.kub.app.builderall.io/payment/wp-funnel-club-plan';
                if(Helper.getEnvs().env == 'prod') window.location.href = 'https://checkout.builderall.com/payment/wp-funnel-club-plan';
                if(Helper.getEnvs().env == 'dev') window.location.href = 'http://checkout.builderall.local/payment/wp-funnel-club-plan';
            }

            //@TODO Logica para o lançamento do 5.0
            if(['1EURBACK','1EUR', '1USDBACK', '1USD', '1BRLBACK', '1BRL'].includes(this.$route.query.coupon !== undefined ? this.$route.query.coupon : '')){
                this.isPaginaCarregada = true;
                this.$router.push({name: 'PlanOrFeeInactive'});
                throw 'plan_or_fee_inactive';
                return;
            }

            if(this.user.isLogged){
                let typeRedir = Helper.redirUserPlanTrialOrPromotional(this.getSlug, this.user, this.userFather);

                if(typeRedir == 'planOrFeeInactive' || (PlanOrFee.data.visivel == '0' && ['premium-builderall-brl-149', 'funil-clube-trial30-brl', 'funnel-club-trial30-usd', 'funnel-club-trial30-eur'].includes(this.getSlug))){
                    this.isPaginaCarregada = true;
                    this.$router.push({name: 'PlanOrFeeInactive'});
                    throw 'plan_or_fee_inactive';
                    return;
                }
                
                if(typeRedir == 'tempRedir') return window.location.href = TempRedirPlan.getUrlRedir();  
                if(typeRedir == 'office') return window.location.href = this.urlOfficeUser;
            }
            
            //Verifica se o plano/taxa é inativo e se possui o redir para outra url.
            if(PlanOrFee.data.visivel == '0' && PlanOrFee.data.redir_on_inactive){
                //se pagamento validade é maior que 60 dias ou não possui o support-link = 1. nao faz o redir
                if(Helper.intervalBetweenDates(this.user.pag_validade).days > 60 || (this.$route.query['support-link'] != undefined && this.$route.query['support-link'] == 1)) return;
                //se tem o pay-now = 1 na url. não faz o redir 
                if(this.$route.query['pay-now'] != undefined && this.$route.query['pay-now'] == 1) return;
                
                window.location.href = `${this.getAllParamsUrl(PlanOrFee.data.redir_on_inactive)}`;
                return
            }
        },
        /**
         * Verificar se é compra de dominio. Caso for e nao tiver os parametros corretos. Redirecionar para o office
         */
        async verifyRedirIfBuyDoaminAndNotParams(){
            if(this.getSlug != Helper.slugBuyDomain) return;

            if(this.getSlug == Helper.slugBuyDomain && (!this.user.isLogged || this.$route.query['payDomain'] != 1 || this.$route.query['domain'] == undefined)){
                window.location.href = this.urlOfficeUser;
                throw 'continue_load';
            }

            this.setPaymentDomain(true);
        },
        async adjustIfPaymentDomain(Fee){
            let domainClassKey = this.$route.query['domainClassKey'] || undefined;
            let privacyProtection = this.$route.query['privacyProtection'] || undefined;
            let domain = this.$route.query['domain'] || undefined;

            let dataDomain = (await fetch(`https://office.builderall.com/${this.user.lang}/${this.endpointOffice.REGISTER_DOMAIN}?domainClassKey=${domainClassKey}&privacyProtection=${privacyProtection}&domain=${domain}`).then(async res => await res.json()));

            let dataRenewDomain = (await fetch(`https://office.builderall.com/${this.user.lang}/${this.endpointOffice.RENEW_DOMAIN}?domain=${domain}`).then(async res => await res.json()));

            if(dataRenewDomain.can_renew == false){
                this.isPaginaCarregada = true;
                this.$router.push({
                    name: 'Unavailable',
                    params: {
                        message: dataRenewDomain.message,
                        actionName: this.translateWord('Ir para Office'),
                        actionHref: this.urlOfficeUser
                    }
                });
                throw 'unavailable';
            }

            Fee.data.currency = dataDomain.currency;
            Fee.data.value = dataDomain.price;
            Fee.data.domain = domain;

            return Fee;
        },
        /**
         * Verifica se é compra de storage extra. Caso for e nao tiver os parametros corretos. Redirecionar para o office
         */
        verifyRedirIfBuyStorageAndNotParams(){
            if(this.getSlug != Helper.slugBuyStorage) return;

            let isExtraStorage = this.$route.query['extraStorage'] || undefined;
            let storageQuantity = this.$route.query['extraStorageQtd'] || undefined;

            if(!this.user.isLogged || isExtraStorage != 1 || !Number.isInteger(parseInt(storageQuantity))){
                window.location.href = this.urlOfficeUser;
                throw 'continue_load';
            }

            this.setPaymentStorage(true);
        },
        async adjustIfPaymentStorage(Plan){
            let storageQuantity = this.$route.query['extraStorageQtd'] || undefined;

            let dataStoragePrice = (await fetch(`https://office.builderall.com/${this.user.lang}/${this.endpointOffice.PRICE_STORAGE}/${storageQuantity}`).then(async res => await res.json()));

            Plan.data.moeda = dataStoragePrice.currency;
            Plan.data.total_real = dataStoragePrice.price;
            Plan.data.storage_kbs = storageQuantity * dataStoragePrice.package * 1024 * 1024;

            return Plan;
        },
        /**
         * Verifica se é compra de dominio extra. Caso for e nao tiver os parametros corretos. Redirecionar para o office
         */
        verifyRedirIfBuyDomainExtraConnectionAndNotParams(){
            if(this.getSlug != Helper.slugBuyDomainExtraConnection) return;

            let isExtraDomain = this.$route.query['extraConnection'] || undefined;
            let extraDomainConnectionQuantity = this.$route.query['extraConnectionQtd'] || undefined;

            if(!this.user.isLogged || isExtraDomain != 1 || !Number.isInteger(parseInt(extraDomainConnectionQuantity))) {
                window.location.href = this.urlOfficeUser;
                throw 'continue_load';
            }

            this.setPaymentDomainExtraConnection(true);
        },
        async adjustIfPaymentDomainExtraConnection(Plan){
            let extraDomainConnectionQuantity = this.$route.query['extraConnectionQtd'] || undefined;

            let dataDomainExtraConnectionPrice = (await fetch(`https://office.builderall.com/${this.user.lang}/${this.endpointOffice.PRICE_DOMAIN_EXTRA_CONNECTION}/${extraDomainConnectionQuantity}`).then(async res => await res.json()));

            Plan.data.moeda = dataDomainExtraConnectionPrice.currency;
            Plan.data.total_real = dataDomainExtraConnectionPrice.price;
            Plan.data.domain_extra_quantity = extraDomainConnectionQuantity;

            return Plan;
        },
        /**
         * Verifica se é compra de dominio. Caso for e nao tiver os parametros corretos. Redirecionar para o office
         */
        verifyRedirIfBuyDomainRegistryAndNotParams(){
            if(this.getSlug != Helper.slugBuyDomainRegistry) return;

            if(this.getSlug == Helper.slugBuyDomainRegistry && !this.user.isLogged ){
                window.location.href = this.urlOfficeUser;
                throw 'continue_load';
            }

            this.setPaymentDomainRegistry(true);
        },
        async adjustIfPaymentDomainRegistry(Fee){
            let domainToken = (await this.$http.get('/auth-domain')).data;
            let orderId = this.$route.query['order'] || undefined;

            try {
                if(!orderId) throw 'the order parameter is invalid';

                let domainDetails = (await this.$httpDomain.get(`/api/v1/order/${orderId}/checkout`, {
                    headers: {
                        'Authorization': `Bearer ${domainToken.token}`
                    }
                })).data;
                let notesdomain = domainDetails.data.my_data;

                this.addDataNote({nota: notesdomain});

                Fee.data.value = domainDetails.data.items[0].value;
                Fee.data.period = domainDetails.data.items[0].period_type;
                Fee.data.time = domainDetails.data.items[0].period;
                Fee.data.domain = domainDetails.data.items[0].title;

                if(domainDetails.data.items[1]){
                    Fee.data.value += domainDetails.data.items[1].value;
                    Fee.data.domainProtection = {
                        period: domainDetails.data.items[1].period_type,
                        time: domainDetails.data.items[1].period,
                        domain: domainDetails.data.items[1].title
                    }
                }
            }catch(errorDomain){
                console.log(errorDomain);
                throw 'error_domain';
            }

            return Fee;
        },
        verifyCurrencyAccept(PlanOrFee){
            
            let planOrFee = PlanOrFee.data.hasOwnProperty('descricao') ? PlanOrFee.data.descricao : PlanOrFee.data.description;
            let currency = PlanOrFee.data.hasOwnProperty('moeda') ? PlanOrFee.data.moeda : PlanOrFee.data.currency;
            
            if(!Helper.getCurrencyAcceppt().includes(currency)){
                this.isPaginaCarregada = true;
                this.$router.push({ name: 'CurrencyNotAvailable', params: { planOrFee, currency } });
                throw 'currency_not_available';
            }            
        },
        verifyPeriodPlanRecurrence(){
            if(this.$route.query['period'] != undefined && this.$route.query['period'] == 'y') this.changePeriodRecurring('YEARLY');
        },
        /**
         * Existe suporte apenas para usuarios logados em BRL. Fora do Brasil pode exibir o suporte
         * estando logado ou não.
         */
        verifySupportEnabled(){
            if(this.user.isLogged && this.product.currency == 'BRL'){
                this.enabledSupport = true;
                this.isSupportBrl = true; 
                return;
            }
            
            if(this.product.currency != 'BRL') this.enabledSupport = true;
        },
        async verifyCoupon(){
            let couponCodeSession = sessionStorage.getItem('coupon');
            let couponCodeUrl = this.$route.query.coupon !== undefined ? this.$route.query.coupon : false;
            let couponCode = couponCodeUrl || couponCodeSession;

            if(couponCodeUrl && !this.product.fakeAmount || couponCodeSession){
                if(couponCodeSession && this.user.isLogged) sessionStorage.removeItem('coupon');

                let hasCouponSuper = Helper.getNewNameCouponSuper(couponCode, this.$route.params.slug);

                if(hasCouponSuper.hasNewNameCoupon) couponCode = hasCouponSuper.newCoupon;

                return this.validateCoupon({
                    couponUser: couponCode,
                    isLoadingCheckout: true
                });
            }

            if(this.product.fakeAmount) this.setFakeAmountPlanOrFee(this.product.fakeAmount);
        },
        async getOrCreateInvoice(){
            let fatura = {
                invoice: {
                    id: null,
                    userOwnerName: null
                }
            };
            let idInvoice = this.$route.query.invoice || false;
            let anyoneCanPay = this.$route.query['anyone-can-pay'] == 1 ? true : false;
            let idUserInvoice = this.$route.query['user-id'] || false;
            
            let dataCreateInvoice = {
                type: this.isBuyTool ? 'plan' : this.isPlan ? 'plan' : 'fee', //@TODO Criar novo type = tool no office para criação de invoice
                slug: this.product.slug,
                gateway_id: Helper.builderallGatewayId
            };
            
            if(this.hasCouponAndIsValid){
                dataCreateInvoice.coupon = {
                    id: this.coupon.id,
                    code: this.coupon.code,
                    type: this.coupon.type,
                    value: this.coupon.value,
                    apply_only_first_month: this.coupon.apply_only_first_month
                };
                
                this.addDataNote({
                    nota: {
                        coupon_id: this.coupon.id,
                        coupon_code: this.coupon.code,
                        coupon_type: this.coupon.type,
                        coupon_value: this.coupon.value,
                        coupon_apply_only_first_month: this.coupon.apply_only_first_month   
                    }
                });
            };
            
            if(idInvoice && (!anyoneCanPay || !idUserInvoice)){
                let validInvoice = (await this.$httpOffice.get(`${this.$store.state.endpointOffice.VALID_INVOICE}/${idInvoice}/${this.user.user_identifier}`)).data;

                //Se a invoice pertence ao usuario, a invoice permace. Caso nao seja, cria uma nova.
                if(validInvoice.valid && validInvoice.data.recorrente_ativo == '0'){
                    fatura['invoice']['id'] = validInvoice.data.id;
                }else{
                    fatura = (await this.requestCreateInvoice(dataCreateInvoice));
                    this.hasInvoiceUrlValid(true);
                }
            }else if(idInvoice && anyoneCanPay && idUserInvoice){
                let validInvoice = (await this.$httpOffice.get(`${this.$store.state.endpointOffice.VALID_INVOICE}/${idInvoice}/${idUserInvoice}`)).data;
                let accountInfoUserInvoice = (await this.$httpOffice.get(`${this.$store.state.endpointOffice.ACCOUNT_INFO}?id=${idUserInvoice}`)).data;
                
                //Se a invoice pertence ao usuario, a invoice permace. Caso nao seja, cria uma nova.
                if(validInvoice.valid){
                    fatura['invoice']['id'] = validInvoice.data.id;
                    fatura['invoice']['userOwnerName'] = accountInfoUserInvoice.name;
                }else{
                    //Exibir uma tela falando que n foi possivel pagar a invoice
                    alert('Não existe invoice')
                }
            }else{
                fatura = (await this.requestCreateInvoice(dataCreateInvoice));
            }

            //@TODO Esta sendo inserindo a invoice dentro da propriedade fatura.invoice, remove o .invoice e deixa apenas o fatura
            localStorage.setItem('idInvoice', fatura.invoice.id);
            /**
             * Salvar na session para pagamento com Paypal quando voltar do paypal
             * quando implementar paypal com o lightbox, pode remover porque nao vai precisar ir ao site do paypal
             */
            sessionStorage.setItem('idInvoice', fatura.invoice.id);

            this.$store.commit('setDadosFatura', fatura);
        },
        async createInvoicePlanToActive(){
            if(!this.isPlanToActive) return;
            
            let invoiceOffice = (await this.$httpOffice.post(`${this.endpointOffice.CRIAR_INVOICE}`, {
                type: 'plan',
                slug: this.isPlan ? this.product.slug : this.product.plan_to_active.slug,
                gateway_id: Helper.builderallGatewayId
            })).data;
            
            this.setInvoicePlanToActive(invoiceOffice.invoice.id);
        },
        async requestCreateInvoice(data){
            if(this.isPaymentDomain){
                let formData = new FormData();
                formData.append('user_id', this.user.cryptId);
                formData.append('currency', this.product.currency);
                formData.append('price', this.product.amount);
                formData.append('domain', this.product.domain);
                formData.append('connect_to', this.$route.query['connect_to']);
                formData.append('reference', this.$route.query['reference']);

                let dataInvoice = (await this.$httpOffice.post(`${this.endpointOffice.CREATE_INVOICE_DOMAIN}/paypal`, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;

                return {
                    invoice: {
                        id: dataInvoice.id,
                        user: {
                            id: this.user.user_identifier
                        }
                    }
                };
            }

            if(this.isPaymentStorage){
                let formData = new FormData();
                formData.append('user_id', this.user.cryptId);
                formData.append('currency', this.product.currency);
                formData.append('price', this.product.amount);
                formData.append('qtd', this.product.storage_kbs);

                let dataInvoice = (await this.$httpOffice.post(`${this.endpointOffice.CREATE_INVOICE_STORAGE}/paypal`, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;

                return {
                    invoice: {
                        id: dataInvoice.id,
                        user: {
                            id: this.user.user_identifier
                        }
                    }
                };
            }

            if(this.isPaymentDomainExtraConnection){
                let formData = new FormData();
                formData.append('user_id', this.user.cryptId);
                formData.append('currency', this.product.currency);
                formData.append('price', this.product.amount);
                formData.append('qtd', this.product.domain_extra_quantity);

                let dataInvoice = (await this.$httpOffice.post(`${this.endpointOffice.CREATE_INVOICE_DOMAIN_EXTRA_CONNECTION}/paypal`, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;

                return {
                    invoice: {
                        id: dataInvoice.id,
                        user: {
                            id: this.user.user_identifier
                        }
                    }
                };
            }

            return (await this.$httpOffice.post(`${this.endpointOffice.CRIAR_INVOICE}`, data)).data
        },
        async paymentPlanWordpressCloud(){
            if(!Helper.slugsPlansWordpressCloud.includes(this.getSlug)) return;

            if(!this.$route.query['stn_wp_order_id']){
                this.isPaginaCarregada = true;
                this.$router.push({
                    name: 'Unavailable',
                    params: {
                        message: this.translateWord('Link invalido. Para acessar o plano Wordpress, clique no botão abaixo'),
                        actionName: this.translateWord('Ir para Wordpress PRO'), 
                        actionHref: 'https://wordpress.builderall.com'
                    }
                });
                throw 'unavailable';
            }

            this.setPaymentPlanWordpressCloud(true);
        },
        async adjustBuyWordpressPrice(PlanOrFee){
            let newAmount = (await this.$httpWordpress.get(`/api/consult-order-amount/${this.$route.query['stn_wp_order_id']}`)).data;

            PlanOrFee.data.total_real = newAmount.price / 100;

            return PlanOrFee;
        },
        async paymentPlanCheetahTemplate(){
            if(!Helper.slugCheetahTemplate.includes(this.getSlug)) return;

            if(!this.$route.query['tp_slug']){
                this.isPaginaCarregada = true;
                this.$router.push({
                    name: 'Unavailable',
                    params: {
                        message: this.translateWord('Link invalido. Para acessar os templates, clique no botão abaixo'),
                        actionName: 'Templates Cheetah', 
                        actionHref: 'https://cheetah-editor.builderall.com/#/create-website?step=template&premium-template=test'
                    }
                });
                throw 'unavailable';
            }

            this.setPaymentCheetahTemplate(true);
        },
        async adjustBuyCheetahTemplate(PlanOrFee){
            try{
                let detailsTemplate = (await this.$httpCheetah.get(`/api/template/premium-templates/${this.$route.query['tp_slug']}`)).data.data.template;

                PlanOrFee.data.value = detailsTemplate.amount;

                this.setCheetahTemplateDetails(detailsTemplate);

                return PlanOrFee;
            }catch(e){
                console.log(e);
            }
        },
        async adjustPaymentCountryPrice(PlanOrFee){
            try{
                let countryPrices = await this.getCountryPrices(this.$route.query['country'] || null);
                let planCountryPrice = countryPrices.find(plan => plan.plan.slug == PlanOrFee.data.slug);
                let isChargedAmount = PlanOrFee.data.hasOwnProperty('currency_charged');

                //Check charged amount value
                PlanOrFee = this.verifyChargedAmount(PlanOrFee, planCountryPrice, isChargedAmount);

                if(isChargedAmount) this.setCountryPriceWithAmountCharged(PlanOrFee);

                //If the plan accessed is not country price. returns the normal access plan details.
                if(planCountryPrice == undefined) return PlanOrFee;

                let dataCountryPrice = {
                    planId: planCountryPrice.plan.id,
                    currency: isChargedAmount ? PlanOrFee.data.currency_charged : planCountryPrice.currency.name,
                    amount: isChargedAmount ? PlanOrFee.data.total_real : planCountryPrice.currency.value,
                    amountFirstMonth: planCountryPrice.plan.value_first_month,
                    symbol: planCountryPrice.currency.symbol,
                    currencyRef: planCountryPrice.plan.ref_currency,
                    amountRef: planCountryPrice.plan.ref_value,
                    amountFirstMonthRef: planCountryPrice.plan.ref_value_first_month,
                    fakeAmount: planCountryPrice.plan.fake_amount
                };

                if(planCountryPrice.plan.value_annual > 0){
                    dataCountryPrice.annual = {
                        amount: planCountryPrice.plan.value_annual,
                        amountRef: planCountryPrice.plan.ref_value_annual,
                        monthAmount: planCountryPrice.plan.value_month,
                        monthAmountRef: planCountryPrice.plan.ref_value_month
                    }
                }

                this.setIsPaymentCountryPrice({
                    status: true,
                    data: dataCountryPrice
                });

                return PlanOrFee;
            }catch(e){
                console.log(e);

                return PlanOrFee;
            }

        },
        setCountryPriceWithAmountCharged(PlanOrFee){
            if(['EUR', 'BRL', 'USD'].includes(PlanOrFee.data.currency_charged)){
                PlanOrFee.data.moeda = PlanOrFee.data.currency_charged;

                return PlanOrFee;
            }

            this.setIsPaymentCountryPrice({
                status: true,
                data: {
                    planId: PlanOrFee.data.id,
                    currency: PlanOrFee.data.currency_charged,
                    amount: PlanOrFee.data.total_real,
                    symbol: `${PlanOrFee.data.currency_charged}$`,
                    currencyRef: 'USD',
                    amountRef: 87.90 //Symbolic value, as the replacement is done in the builderall gateway backend when sending the notification with country price
                }
            });
        },
        verifyChargedAmount(PlanOrFee, PlanCountryPrice, IsChargedAmount){
            if(IsChargedAmount && this.user.current_plan.id == PlanOrFee.data.id) this.setIsChargedAmount(true);

            if(['EUR', 'BRL', 'USD'].includes(PlanOrFee.data.currency_charged)){
                PlanOrFee.data.moeda = PlanOrFee.data.currency_charged;

                return PlanOrFee;
            }

            PlanCountryPrice = PlanCountryPrice || null;

            if(IsChargedAmount && this.user.current_plan.id == PlanOrFee.data.id){
                PlanOrFee.data.moeda = PlanOrFee.data.currency_charged;
                PlanOrFee.data.symbol = PlanOrFee.data.currency_charged;

                this.setIsChargedAmount(true);

                if(PlanCountryPrice && PlanCountryPrice.plan.value_first_month > 0) PlanCountryPrice.plan.value_first_month = PlanOrFee.data.total_real;

                return PlanOrFee;
            }

            if(PlanCountryPrice){
                PlanOrFee.data.total_real = PlanCountryPrice.currency.value;
                PlanOrFee.data.moeda = PlanCountryPrice.currency.name;
                PlanOrFee.data.symbol = PlanCountryPrice.currency.symbol;
            }


            return PlanOrFee;
        },
        blockPaymentOutsideCurrencyBrazil(){
            //Libera pagamentos em BRL para fora do brasil.
            if(this.$route.query['allowed-brl'] != undefined && this.$route.query['allowed-brl'] == 1) return;

            //Libera pagamentos em BRL para fora do brasil quando for pagamento por: Dominio, Storage e Dominio Extra
            if(this.isPaymentDomain || this.isPaymentStorage || this.isPaymentDomainExtraConnection) return;

            if(this.product.currency == 'BRL' && this.localeUser.country != 'BR'){
                this.isPaginaCarregada = true;
                this.$router.push({
                    name: 'Unavailable',
                    params: {
                        message: this.translateWord('Planos em BRL sao permitidos apenas para usuarios no Brasil'),
                        actionName: this.translateWord('Ir para Office'),
                        actionHref: this.urlOfficeUser
                    }
                });
                throw 'unavailable';
            }
        },
        async verifyCustomThankYouPage(urlRedirectDefaultPlanOrFee){
            let sd = this.$route.query.sd || null;
            
            if(sd === null){
                //Alguns casos o #aid vem no inicio da URL, e devido a este motivo, 
                //Não pega o parametro sd, quando existe
                let urlWithHash = new URLSearchParams(decodeURIComponent(location.hash.replace('#', '')));
                if(urlWithHash.get('sd')){
                    sd = urlWithHash.get('sd');
                }else{
                    return urlRedirectDefaultPlanOrFee;
                }
            }

            let positionHash = sd.indexOf('#');
            this.subdomain = positionHash < 0 ? sd : sd.substring(0, positionHash);

            this.setDataUserFather({ subdomain: this.subdomain });

            try{
                let createParamsOffice = `is_default=${this.subdomain == 'default_ilm' ? true : false}&aid=${this.aid}&subdomain=${this.subdomain}`;
                let result = await this.$httpOffice.get(`${this.endpointOffice.GET_CUSTOM_THANK_YOU_PAGE}?${createParamsOffice}`);

                return result.data.url != null ? result.data.url : urlRedirectDefaultPlanOrFee;
            }catch(error){
                console.log(error);
                return urlRedirectDefaultPlanOrFee;
            }
        },
        async verifyPixelAnalytics(){            
            let pixelConfigureUser = (await this.$httpOffice.get(`${this.endpointOffice.GET_PIXEL_FUNNEL}?aid=${this.aid}&subdomain=${this.subdomain}`)).data;
            //let pixelConfigureUser = {"msg":"sucess","data":{"pixels":[{"type":"facebook","id":"250516566876308","access_token":"EAANW6cfUjowBAMMHTb0QNbJ6vVAeDf1bpcqCZCG0nCEZC62x6Ip0V63erAqxL9mEXD1qfeCcZCh1ZBHvu4e7HdLWGPZAHmKWY8HNA0F3nJI4Hv1BjSw0kkDxMHSc5VBj2zv4ZBddcQKIrz93JwHSOte2C5wgCvRC5M6h5YwtF3nCAdB5kyQKSz0ZCWVlBaMSsMZD"}],"tracking_tag":"tagexemplo","tracking_pixel_url":null,"whatsapp_group_url":null,"custom_thankyou_page_url":null,"tracking_pixel_success_url":null,"funnel_default_thankyou_page_url":"https:\/\/magazine.builderall.com\/thankyou","funnels_domain":"deepfirst.xyz"},"status":200,"details":"Success","subdomain":"magazine-en"};
            let pixelLead = this.$route.query.pixelEvent || null;

            if(Array.isArray(pixelConfigureUser.data.pixels)){
                pixelConfigureUser.data.pixels.forEach(async pixel => {
                    if(pixel.type == 'facebook'){                        

                        if(pixel.hasOwnProperty('access_token') && pixel['access_token'] != ''){
                            PixelAnalytics.setFbData(
                                pixel.id, 
                                pixel.access_token, 
                                pixelConfigureUser.data.funnels_domain,
                                this.$route.query.ur || null,
                                this.subdomain || null
                            );
                            
                            let pixelFbPageView = await generateFacebookEventId('PageView');
                            
                            sendPageViewEvent(
                                PixelAnalytics.fbData.accessToken,
                                PixelAnalytics.fbData.id,
                                PixelAnalytics.fbData.funnelsDomain,
                                pixelFbPageView,
                                PixelAnalytics.fbData.ur,
                                PixelAnalytics.fbData.subdomain
                            );

                            PixelAnalytics.facebookPixel(pixel.id); //265128684601309

                            PixelAnalytics.createEventPixel('fb', 'PageView', {}, pixelFbPageView);

                            if(this.user.isLogged){
                                let pixelFbInitiateCheckout = await generateFacebookEventId('InitiateCheckout');

                                sendInitiateCheckoutEvent(
                                    PixelAnalytics.fbData.accessToken,
                                    PixelAnalytics.fbData.id,
                                    PixelAnalytics.fbData.funnelsDomain,
                                    pixelFbInitiateCheckout,
                                    this.product.currency,
                                    this.productAmount,
                                    this.product.title,
                                    Helper.firstName(this.user.name),
                                    Helper.lastName(this.user.name),
                                    this.user.email,
                                    PixelAnalytics.fbData.ur,
                                    PixelAnalytics.fbData.subdomain
                                );
                                
                                
                                PixelAnalytics.createEventPixel('fb', 'InitiateCheckout', {
                                    name: this.product.title,
                                    value: this.productAmount,
                                    currency: this.product.currency
                                }, pixelFbInitiateCheckout);

                                if(pixelLead) PixelAnalytics.createEventPixel('fb', pixelLead);
                            }
                            
                        }else{
                            PixelAnalytics.facebookPixel(pixel.id); //265128684601309

                            PixelAnalytics.createEventPixel('fb', 'PageView');
                            
                            PixelAnalytics.createEventPixel('fb', 'InitiateCheckout', {
                                name: this.product.title,
                                value: this.productAmount,
                                currency: this.product.currency
                            });

                            if(pixelLead) PixelAnalytics.createEventPixel('fb', pixelLead);   
                        }
                    }

                    if(pixel.type == 'google-analytics') {
                        PixelAnalytics.googleAnalytics(pixel.id); //GA-12764628

                        PixelAnalytics.createEventPixel('ga', null, {
                            hitType: 'event',
                            location: location.href,
                            page: location.pathname,
                            eventCategory: 'InitiateCheckout',
                            eventAction: 'pageload',
                            eventLabel: `Initiate checkout of ${this.product.title} ${this.product.currency}${this.product.amountCoupon ? this.product.amountCoupon : this.product.amount}`
                        });

                        if(pixelLead) PixelAnalytics.createEventPixel('ga', null, {
                            hitType: 'event',
                            location: location.href,
                            page: location.pathname,
                            eventCategory: pixelLead
                        });
                    }

                    if(pixel.type == 'google-tag-manager') {
                        PixelAnalytics.googleTagManager(pixel.id); //UA-136599186-1
                    }
                });
            }

            if(pixelConfigureUser.data.tracking_pixel_url){
                PixelAnalytics.insertDirectDom(pixelConfigureUser.data.tracking_pixel_url);
            }

            PixelAnalytics.addDataLayer('InitiateCheckout', {
                name: this.product.title,
                currency: this.product.currency,
                amount: this.productAmount,
                transaction_id: this.fatura.invoice !== undefined ? this.fatura.invoice.id : null
            });

            if(pixelLead) PixelAnalytics.addDataLayer(pixelLead);
        },
        /**
         * Pixel feito para o murilo
         *
         * @returns {Promise<void>}
         */
        async verifyPixelAnalyticsParent(){
            let pixelLead2 = this.$route.query['pixel-event'] || null;
            
            if(!pixelLead2) return;
            
            let pixelConfigureUser = (await this.$httpOffice.post(this.endpointOffice.GET_PARENT_PIXEL, Helper.serialize({
                event: pixelLead2,
                user_id: this.user.user_identifier
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })).data;
            
            if(!pixelConfigureUser.success) return;
            pixelConfigureUser = pixelConfigureUser.data;
            
            if(pixelConfigureUser.facebook){
                PixelAnalytics.facebookPixel(pixelConfigureUser.facebook);

                PixelAnalytics.createEventPixel('fb', 'InitiateCheckout', {
                    name: this.product.title,
                    value: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount,
                    currency: this.product.currency
                });

                console.log(`Pixel Facebook: ${pixelConfigureUser.facebook}`);
            }
            
            if(pixelConfigureUser.google_analytics){
                PixelAnalytics.googleAnalytics(pixelConfigureUser.google_analytics);

                PixelAnalytics.createEventPixel('ga', null, {
                    hitType: 'event',
                    location: location.href,
                    page: location.pathname,
                    eventCategory: 'InitiateCheckout',
                    eventAction: 'pageload',
                    eventLabel: `Initiate checkout of ${this.product.title} ${this.product.currency}${this.product.amountCoupon ? this.product.amountCoupon : this.product.amount}`
                });

                console.log(`Pixel Google Analitcs: ${pixelConfigureUser.google_analytics}`);
            }
            
            if(pixelConfigureUser.google_tag_manager){
                PixelAnalytics.googleTagManager(pixelConfigureUser.google_tag_manager);

                console.log(`Pixel Google Tag Manager: ${pixelConfigureUser.google_tag_manager}`);
            }

            PixelAnalytics.addDataLayer('InitiateCheckout', {
                name: this.product.title,
                currency: this.product.currency,
                amount: this.product.amountCoupon ? this.product.amountCoupon : this.product.amount,
                transaction_id: this.fatura.invoice !== undefined ? this.fatura.invoice.id : null
            });
            
            
        },
        verifyPixelBuilderall(){
            let envProd = Helper.getEnvs().env == 'prod' ? true : false;

            if(envProd && this.$route.query['skipStepConfirmation'] && !this.$route.query['testPixel']) return;

            PixelAnalytics.googleAnalytics4(envProd ? 'G-20WR450BYB' : 'G-LTJRS92H6S');

            if(envProd){
                setTimeout(() => PixelAnalytics.googleTagManager('GTM-KFN45NN'), 4000);
            }

        },
        async verifyPauseAccount(plan){
            if(!this.$route.query['pause_account'] || !this.$route.query['currency']) return plan;
            
            let amountPauseAccount = 1500;
            let currencyDefault = 'USD';
            let currencyTarget = this.$route.query['currency'];

            if(currencyTarget != currencyDefault){
                let amountConvert = (await this.$http.post('/currency/converter?authentication=BGPUBLIC_11032024', {
                    currency_target: currencyTarget,
                    currency_base: 'USD',
                    amount_base: amountPauseAccount,
                })).data.data;
                
                plan.data.moeda = currencyTarget;
                plan.data.symbol = `${currencyTarget}$ `;
                plan.data.total_real = amountConvert.amount_target / 100;
            }else{
                plan.data.moeda = currencyDefault;
                plan.data.symbol = `$`;
                plan.data.total_real = amountPauseAccount / 100;
            }
            
            
            this.setIsPauseAccount(true);

            return plan;
        },
        paramUrlAfterPaymentAjust(){
            let urlReplaceInterrogation = new URLSearchParams(window.location.href.replace('?', '&'));
            let urlAfterPayment = urlReplaceInterrogation.get('url-after-payment');
            if(urlAfterPayment) decodeURIComponent(urlAfterPayment);

            if(urlAfterPayment){
                decodeURIComponent(urlAfterPayment);

                //Se tiver o url-after-payment na URL remove caso exista em localStorage
                localStorage.removeItem('canSkipStep');
                localStorage.removeItem('redirectUrlAfterPayment');
            }

            return urlAfterPayment != undefined ? urlAfterPayment.replace(/\|/g, '/') : null;
        },
        showSelectPaymentMethod(){
            if(this.isPaymentDomain || this.isPaymentStorage || this.isPaymentDomainExtraConnection) return this.enabledPaypal(true);

            if(Helper.getCurrencyAcceppt().includes(this.product.currency)){

                if(this.isPaymentOnlyPaypal){
                    this.enabledPaypal(true);
                    this.selectPaymentMethod('paypal');
                }

                this.enabledCreditCard(true);

                if(!['BRL'].includes(this.product.currency)) this.enabledPaypal(true);

                if(this.isPaymentDomainRegistry || this.getSlug == 'email-marketing-mastery' || this.isPauseAccount) this.enabledPaypal(false);

                this.selectPaymentMethod('creditCard');

            }

            if(['EUR'].includes(this.product.currency) && !this.product.isTrialDays) this.enabledSepa(true);

            if(['BRL'].includes(this.product.currency) && !this.product.isTrialDays){
                this.enabledBoleto(true);
                this.enabledPix(true);
            }

            if(this.user.hasOwnProperty('credit_cards') && this.user.credit_cards.length != 0 && !this.product.isTheCurrency.eur) {
                let cards = this.user.credit_cards.filter(cards => cards.currency == this.product.currency);

                if(cards.length != 0){
                    this.setMyCreditCard(cards);
                    this.enabledMyCards(true);
                    this.selectPaymentMethod('myCards');
                }
            }

            if(this.hasBundlePlan){
                this.enabledPaypal(false);
                this.enabledBoleto(false);
                this.enabledPix(false);
            }
        },
        async verifyFirstPaymentUpLinePaymentGateway(){
            let isAmountFixed = ['1000.00', '500.00', '199.00'].includes(this.product.amountFirstMonth || this.product.amount);
            
            if(this.product.first_payment_to_upline && this.userFather.receivement_gateways != null && this.product.currency != 'EUR' && !this.hasCouponAndIsValid && isAmountFixed){
                let formData = new FormData();
                formData.append('user_id', this.user.cryptId);
                formData.append('type', this.isPlan ? 'PLAN' : 'FEE');
                formData.append('reference', this.product.id);

                let sponsorReceiver = (await this.$httpOffice.post(this.endpointOffice.SPONSOR_RECEIVER_IS_VALID, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })).data;

                if(sponsorReceiver.success && sponsorReceiver.is_valid){
                    if(['BRL'].includes(this.product.currency) && this.userFather.receivement_gateways.hasOwnProperty('pagseguro') && !this.paymentMethodEnabled.pagSeguro){
                        this.enabledPagSeguro(true);
                    }

                    if(this.userFather.receivement_gateways.hasOwnProperty('paypal') && !this.paymentMethodEnabled.paypal){
                        this.enabledPaypal(true);
                    }
                    
                    this.isPaymentUpLine(true);
                }
            }
        },
        async createTokenCheckout(){
            try{
                let tokenDetails = (await this.$http.post('/user/token-checkout', {
                    application: 'OFFICE',
                    id_user: this.user.user_identifier
                })).data;

                this.setTokenCheckout(tokenDetails.token);
            }catch{

            }
            
        }
    },
    components: {
        Loading,
        FullScreenLoading,
        SupportChat
    }
}
</script>

<style lang="scss" src="./scss/main.scss">

</style>


